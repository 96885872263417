import { createReducer, on } from '@ngrx/store';
import * as categoryActions from '../actions/category.actions';

export const categoryReducerFeatureKey = 'categoryReducer';

export interface State {
  categories: any[],
  selectedCategory: any,
  isLoading: boolean
}

export const initialState: State = {
  categories: [],
  selectedCategory: {},
  isLoading: false
};

export const CategoryReducer = createReducer(initialState,
  on(categoryActions.loadCategories, () => ({ ...initialState, isLoading: true})),
  on(categoryActions.loadCategoriesSuccess, (state, action) => ({ ...state, categories: action.categories, isLoading: false })),
  on(categoryActions.CreateCategory, (state) => ({...state, isloading: true })),
  on(categoryActions.CreateCategorySuccess, (state, action) => ({...state, categories:[...state.categories, action.category] , isloading: false })),
  on(categoryActions.LoadSelectedCategorySuccess, (state, action) => ({ ...state, selectedCategory: action.category })),
  on(categoryActions.updateCategory, (state) => ({...state, isloading: true })),
  on(categoryActions.UpdateCategorySuccess, (state) => ({...state, isloading: false })),
  on(categoryActions.DeleteCategory, (state) => ({...state, isloading: true })),
  on(categoryActions.DeleteCategorySuccess, (state, action) => ({...state, isloading: false, categories: state.categories.filter(category => category.id != action.id) })),
)
