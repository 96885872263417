import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import * as productsActions from '../actions/products.actions';
import { HttpProductsService } from '../http-services/http-products.service';
import { AlertService } from '../services/alert.service';
import { AlertTypes } from '../enums/alertTypes';

@Injectable()
export class ProductsEffects {
  public constructor(
    private actions$: Actions,
    private service: HttpProductsService,
    private alertService: AlertService
  ) {}

  public loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.loadProducts),
      switchMap((action) =>
        this.service.getProducts(action.subCategory, action.category, action.collection, action.isOnSale).pipe(
          map((products) => productsActions.loadProductsSuccess({ products })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public loadAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.loadAllProducts),
      switchMap(() =>
        this.service.getAllProducts().pipe(
          map((products) => productsActions.loadAllProductsSuccess({ products })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public loadProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.LoadSelectedProduct),
      switchMap((action) => {
        return this.service.getProductById(action.id).pipe(
          map((product) =>
            productsActions.LoadSelectedProductSuccess({ product })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  public createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.createProduct),
      switchMap((action) =>
        this.service.createProduct(action.product).pipe(
          map(() => {
            this.alertService.showAlert('تمت اضافة المنتج بنجاح', AlertTypes.success, 2000)
            return productsActions.CreateProductSuccess()
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );

  public updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.updateProduct),
      switchMap((action) =>
        this.service.updateProduct(action.product).pipe(
          map(() =>{
            this.alertService.showAlert('تم تعديل المنتج بنجاح', AlertTypes.success, 2000)
            return productsActions.UpdateProductSuccess()  
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );

  public deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.DeleteProduct),
      switchMap((action) => {
        return this.service.deleteProduct(action.id).pipe(
          map((id) => {
            this.alertService.showAlert('تمت حذف المنتج بنجاح', AlertTypes.success, 2000)
            return productsActions.DeleteProductSuccess({id})
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        );
      })
    )
  );
}
