<div class="card p-2 form-card" style="width: 20rem">
  <div class="card-body">
    <h6 class="card-title text-end white-text">إضافة تصنيف جديد</h6>
    <div class="form-floating mb-1">
      <input
        type="text"
        class="form-control form-control-sm"
        id="name"
        placeholder="الإسم"
        (blur)="onBlur('category')"
        [(ngModel)]="categoryName"
        dir="rtl"
      />
      <label for="name">...أدخل إسم التصنيف</label>
    </div>
    <div *ngIf="isCategoryNameTouched && categoryName == '' " class="text-warning mb-2">
      التصنيف مطلوب
    </div>
    <select class="form-select" [(ngModel)]="collection" (blur)="onBlur('collection')">
      <option *ngFor="let collection of collections" value="{{ collection }}">
        {{ collection }}
      </option>
    </select>
    <div *ngIf="isCollecctionTouched && collection == ''" class="text-warning">
      المجموعة مطلوبة
    </div>
    <div class="m-3" style="overflow-y: scroll; max-height: 110px">
      <table class="table">
        <thead>
          <tr class="white-text">
            <th scope="col">#</th>
            <th scope="col">التصنيف الفرعي</th>
          </tr>
        </thead>
        <tbody class="white-text">
          <tr *ngFor="let subCategory of subCategories; let i = index">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ subCategory.name }}</td>
            <button type="button" id="clear" class="btn-close" aria-label="Close" (click)="removeSubCategory(i)"></button>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex">
      <input
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="subCategory"
        (blur)="onBlur('subCategory')"
        placeholder="...اضف تصنيف فرعي"
        dir="rtl"
      />
      <button
        class="btn btn-sm ms-2 btn-outline-light"
        (click)="addSubCategory()"
        [disabled]="subCategory == ''"
      >
        أضف
      </button>
    </div>
    <div *ngIf="isSubCategoryTouched && subCategory == ''" class="text-warning">
       التصنيف الفرعي مطلوب
    </div>
    <hr />
    <div class="row justify-content-center">
      <button
        type="submit"
        class="btn btn-sm btn-outline-light"
        (click)="createCategory()"
        [disabled]="categoryName == '' || collection == '' || subCategories.length == 0"
      >
        حفظ التصنيف
      </button>
    </div>
  </div>
</div>
