import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { State } from '../reducers/category.reducers';
import * as fromSelector from '../selectors/products.selectors';
import * as productsActions from '../actions/products.actions';
import { Store } from '@ngrx/store';
import { ProductModel } from '../models/productModel';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  public products$: Observable<any[]> = this.store.select(fromSelector.products);
  public isLoading$: Observable<boolean> = this.store.select(fromSelector.isLoading);
  public isSelectedProductLoading$: Observable<boolean> = this.store.select(fromSelector.isSelectedProductLoading);
  public selectedProduct$: Observable<any> = this.store.select(
    fromSelector.selectedProduct
  );

  constructor(private store: Store<State>) {}

  public loadProducts(
    subCategory: number,
    category: number,
    collection: string | null,
    isOnSale: boolean
  ) {
    this.store.dispatch(
      productsActions.loadProducts({ subCategory, category, collection, isOnSale })
    );
  }

  public loadAllProducts(){
    this.store.dispatch(productsActions.loadAllProducts())
  }

  public SelectProduct(id: number) {
    this.store.dispatch(productsActions.LoadSelectedProduct({ id }));
  }

  public createProduct(product: ProductModel) {
    this.store.dispatch(productsActions.createProduct({ product }));
  }

  public updateProduct(product: ProductModel) {
    this.store.dispatch(productsActions.updateProduct({ product }));
  }

  public deleteProduct(id: number){
    this.store.dispatch(productsActions.DeleteProduct({id}))
  }
}
