import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public showAlert(content: string, type: string, showTime: number): void {
    const alertElement = document.createElement('div');
    alertElement.className = `alert alert-dismissible fade show alert-${type}` ;
    alertElement.innerHTML = `<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>${content}`;
    document.body.appendChild(alertElement);

    setTimeout(() => {
      alertElement.remove();
    }, showTime);
  }
}
