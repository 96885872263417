import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/products.reducers'

export const selectState = createFeatureSelector<fromReducer.State>(
  fromReducer.productsReducerFeatureKey,
);

export const products = createSelector(
  selectState,(state: fromReducer.State) => state.products
);

export const selectedProduct = createSelector(
  selectState, (state: fromReducer.State) => state.selectedProduct
);

export const isLoading = createSelector(
  selectState, (state: fromReducer.State) => state.isLoading
);

export const isSelectedProductLoading = createSelector(
  selectState, (state: fromReducer.State) => state.isSelectedProductLoading
);
