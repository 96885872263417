import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/orders.reducers'

export const selectState = createFeatureSelector<fromReducer.State>(
  fromReducer.ordersReducerFeatureKey,
);

export const orders = createSelector(
  selectState,(state: fromReducer.State) => state.orders
);

export const isLoading = createSelector(
  selectState, (state: fromReducer.State) => state.isLoading
);
