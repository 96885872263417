<div class="container-fluid pt-3 background">
  <div class="row mb-4 px-3">
    <nav aria-label="breadcrumb">
      <ol *ngIf="category !== null" class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/home']">الصفحة الرئيسية</a>
        </li>
        <li class="breadcrumb-item">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: category.collection }"
            >قسم {{ category.collection }}</a
          >
        </li>
        <li class="breadcrumb-item">
          <a
            [routerLink]="['/products']"
            [queryParams]="{
              category: category.id,
              collection: category.collection
            }"
            >{{ category.name }}</a
          >
        </li>
        <li class="breadcrumb-item">
          <a
            [routerLink]="['/products']"
            [queryParams]="{
              subCategory: selectedProduct.subCategory.id,
              category: category.id,
              collection: category.collection
            }"
            >{{ selectedProduct.subCategory.name }}</a
          >
        </li>
        <li *ngIf="selectedProduct.brand" class="breadcrumb-item active">
          {{ selectedProduct.brand }}
        </li>
      </ol>
    </nav>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-10 col-sm-10 col-lg-8">
      <div class="row">
        <div
          class="col-sm-6 image-detail border d-flex align-items-center justify-content-center bg-white"
        >
          <div
            *ngIf="!(isSelectedProductLoading$ | async); else loading"
            class="text-center pd-image"
          >
            <app-image-slider
              [images]="selectedProductDetail.images"
            ></app-image-slider>
          </div>
        </div>
        <div class="col-sm-6 p-3 my-4 bg-detail">
          <div class="mb-3">
            <h6 class="text-uppercase">الموديل</h6>
            <ng-container *ngFor="let pd of selectedProduct.details">
              <label class="radio me-1">
                <input
                  type="radio"
                  name="image"
                  [(ngModel)]="selectedProductDetail"
                  [value]="pd"
                  (change)="onColorChange()"
                />
                <img src="{{ pd.images[0] }}" alt="..." />
              </label>
            </ng-container>
          </div>
          <div class="mb-3">
            <h6>{{ selectedProduct.name }}</h6>
            <span class="brand">{{ selectedProduct.brand }}</span>

            <div class="d-flex">
              <div
                *ngIf="selectedProduct.salePrice !== 0"
                class="price d-flex flex-row align-items-center me-2"
              >
                <strong style="color: orangered">{{
                  selectedProduct.salePrice | currency : "ILS"
                }}</strong>
              </div>
              <div
                class="price d-flex flex-row align-items-center"
                [class.old-price]="selectedProduct.salePrice !== 0"
              >
                <strong style="color: orangered">{{
                  selectedProduct.price | currency : "ILS"
                }}</strong>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <p class="text-uppercase mb-1">الحجم</p>
            <ng-container *ngFor="let size of availableSizes">
              <label
                class="radio me-1"
                [ngClass]="{ disabled: size.count === 0 }"
              >
                <input
                  type="radio"
                  name="size"
                  [(ngModel)]="selectedSize"
                  [value]="size"
                  [disabled]="size.count === 0"
                />
                <span>{{ size.name }}</span>
              </label>
            </ng-container>
          </div>
          <div>
            <label for="quantity" class="mt-2 mb-1">الكمية</label>
            <input
              [(ngModel)]="quantity"
              type="number"
              class="form-control border border-1 w-25"
              min="1"
              id="quantity"
              name="quantity"
              required
            />
            <div class="d-flex mt-3">
              <button
                class="btn btn-sm btn-danger me-2 px-2"
                (click)="addToCart()"
              >
                إضافة إلى السلة
                <i
                  class="bi bi-cart fs-6 ms-2"
                  style="color: white; font-size: 20px"
                ></i>
              </button>
              <button
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#descriptionModal"
                (click)="setDescription(selectedProduct.description)"
              >
                التفاصيل
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="similarProducts.length">
    <div class="row mt-5 me-2 text-end"><h5>منتجات مشابهة</h5></div>
    <div class="row justify-content-center">
      <div
        *ngIf="!(isProductsLoading$ | async); else loading"
        class="container mt-2"
      >
        <div
          id="imageSlider"
          class="carousel slide carousel-dark"
          direction="rtl"
        >
          <div class="carousel-inner">
            <ng-container
              *ngFor="
                let group of similarProducts | chunk : chunkSize;
                let i = index
              "
            >
              <div class="carousel-item" [class.active]="i == 0">
                <div class="row justify-content-end slider-images">
                  <div *ngFor="let product of group" class="{{ columnClass }}">
                    <div
                      class="card border-0 similar-product-card mb-3 bg-transparent"
                    >
                      <img
                        src="{{ product.details.image }}"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body text-end">
                        <h5 class="card-title">{{ product.name }}</h5>
                        <p class="card-text h6">{{ product.brand }}</p>
                        <div
                          class="card-text h5 mb-1"
                          [class.old-price]="product.salePrice !== 0"
                          style="color: orangered"
                        >
                          <a
                            [routerLink]="['/products', product.id, 'details']"
                            class="btn stretched-link"
                          ></a>
                          {{ product.price | currency : "ILS" }}
                        </div>
                        <div
                          *ngIf="product.salePrice !== 0"
                          class="card-text h5"
                          style="color: orangered"
                        >
                          {{ product.salePrice | currency : "ILS" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#imageSlider"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#imageSlider"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row bg-transparent" style="height: 150px"></div>
  <ng-template #loading>
    <div class="col-md-3">
      <img
        class="w-100 h-75"
        src="../../../../assets/images/loading.gif"
        alt="..."
      />
    </div>
  </ng-template>
</div>

<div
  class="modal fade"
  id="descriptionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="descriptionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="descriptionModalLabel">الوصف</h6>
        <div class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"
            ><span class="path1"></span><span class="path2"></span
          ></i>
        </div>
      </div>
      <div class="modal-body overlay overlay-block text-end">
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</div>
