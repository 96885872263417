import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs';
import { Collections } from 'src/app/enums/collections';
import { CategoryModel, SubCategoryModel } from 'src/app/models/categoryModel';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.css'],
})
export class CategoryFormComponent implements OnInit {
  public subCategory: string = '';
  public subCategories: SubCategoryModel[] = [];
  public categoryName: string = '';
  public collection: string = '';
  public collections: string[] = [];
  public id: number = 0;
  public isCategoryNameTouched: boolean = false;
  public isCollecctionTouched: boolean = false;
  public isSubCategoryTouched: boolean = false;

  constructor(
    private categoryService: CategoryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.collections = Object.values(Collections);

    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id) this.categoryService.loadSelectedCategory(this.id);
    });
    
    if (this.id) {
      this.categoryService.selectedCategory$.pipe(filter(x => !!x?.name),take(1)).subscribe((category) => {
        this.categoryName = category.name;
        this.collection = category.collection;
        this.subCategories = category.subCategories;
      });
    }
  }

  public addSubCategory() {
    if(this.subCategory == '')
      return;
    this.subCategories = [...this.subCategories, { name: this.subCategory }];
    this.subCategory = '';
    this.isSubCategoryTouched = false
  }

  public createCategory() {
    const model = {
      id: this.id,
      name: this.categoryName,
      collection: this.collection,
      subCategories: this.subCategories,
    } as CategoryModel;

    this.id
      ? this.categoryService.updateCategory(model)
      : this.categoryService.createCategory(model);

    this.categoryName = '';
    this.collection = '';
    this.subCategories = [];
    this.isCategoryNameTouched = false
    this.isCollecctionTouched = false
  }

  public removeSubCategory(index: number) {
    this.subCategories = this.subCategories
      .slice(0, index)
      .concat(this.subCategories.slice(index + 1));
  }

  public onBlur(string: string) {
    if (string === 'category') {
      this.isCategoryNameTouched = true;
      return;
    }
    if (string === 'collection') {
      this.isCollecctionTouched = true;
      return;
    } else this.isSubCategoryTouched = true;
  }
}
