import { createAction, props } from '@ngrx/store';
import { SizeModel } from '../models/sizeModel';

export const loadSizes = createAction('Load Sizes');
export const loadSizesSuccess = createAction(
  'Load Sizes Success',
  props<{ sizes: any[] }>()
);
export const CreateSize = createAction(
  'Create Size',
  props<{ size: SizeModel }>()
);
export const CreateSizeSuccess = createAction('Create Size Success', props<{ size: any }>());
export const CreateSizeFailure = createAction(
  'Create Size Failure',
  props<{ error: any }>()
);
