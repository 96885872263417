import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../reducers/ads.reducers';
import * as fromSelector from '../selectors/ads.selectors'
import * as adActions from '../actions/ads.actions'
import { AdModel } from '../models/adModel';

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  public ads$: Observable<any> = this.store.select(fromSelector.ads);

  constructor(private store: Store<State>) { }

  public loadAd(){
    this.store.dispatch(adActions.loadAd());
  }

  public createAd(ad: AdModel){
    this.store.dispatch(adActions.CreateAd({ad}));
  }
}
