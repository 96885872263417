import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginModel } from '../models/loginModel';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpUserService {
  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  public login(model: LoginModel): Observable<string>{
    return this.http.post(this.baseUrl + '/user/login', model, {responseType: 'text'});
  }

  public loadUser(token: string): Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`)
    return this.http.get(this.baseUrl + '/user', {headers})
  }
}
