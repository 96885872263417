import { createReducer, on } from '@ngrx/store';
import * as cartActions from '../actions/cart.actions';

export const cartReducerFeatureKey = 'cartReducer';

export interface State {
  products: any[];
  totalPrice: number;
}

export const initialState: State = {
  products: [],
  totalPrice: 0,
};

export const cartReducer = createReducer(
  initialState,
  on(cartActions.addProduct, (state, action) => ({
    ...state,
    products: [...state.products, action.product],
    totalPrice: state.totalPrice + action.product.price,
  })),
  on(cartActions.removeProduct, (state, action) => ({
    ...state,
    products: state.products.filter((i) => i.id !== action.product.id),
    totalPrice: state.totalPrice - action.product.price,
  }))
);
