<div class="container-fluid mt-3">
  <div class="row justify-content-center mt-3">
    <div class="col-sm-3 me-2">
      <div class="card border-0 img-wrapper">
        <img
          src="../../../assets/images/Women.jpg"
          class="rounded card-img-top"
          alt="Women"
        />
        <div class="card-body card-img-overlay custom-position">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: collections.Women }"
            class="btn btn-light stretched-link collection-button"
            >قسم {{ collections.Women }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-sm-3 me-2">
      <div class="card border-0">
        <img
          src="../../../assets/images/Men.jpg"
          class="rounded card-img-top"
          alt="Men"
        />
        <div class="card-body card-img-overlay custom-position">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: collections.Men }"
            class="btn btn-light stretched-link"
            >قسم {{ collections.Men }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="card border-0">
        <img
          src="../../../assets/images/Kids.jpg"
          class="rounded card-img-top"
          alt="Kids"
        />
        <div class="card-body card-img-overlay custom-position">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: collections.Kids }"
            class="btn btn-light stretched-link"
            >قسم {{ collections.Kids }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5 py-2 second-row">
    <div class="col-sm-3 me-2">
      <div class="card border-0">
        <img
          src="../../../assets/images/Perfumes.png"
          class="rounded card-img-top"
          alt="Perfumes"
        />
        <div class="card-body card-img-overlay custom-position">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: collections.Perfumes }"
            class="btn btn-light stretched-link"
            >قسم {{ collections.Perfumes }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-sm-3 me-2">
      <div class="card border-0">
        <img
          src="../../../assets/images/HomeAccessories.png"
          class="rounded card-img-top"
          alt="HomeAccessories"
        />
        <div class="card-body card-img-overlay custom-position">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: collections.HomeAccessories }"
            class="btn btn-light stretched-link"
            >قسم {{ collections.HomeAccessories }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="card border-0">
        <img
          src="../../../assets/images/SkinCare.png"
          class="rounded card-img-top"
          alt="SkinCare"
        />
        <div class="card-body card-img-overlay custom-position">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ collection: collections.SkinCare }"
            class="btn btn-light stretched-link"
            >قسم {{ collections.SkinCare }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row bg-transparent" style="height: 150px;"></div>
</div>
