import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/sizes.reducer'

export const selectState = createFeatureSelector<fromReducer.State>(
  fromReducer.sizesReducerFeatureKey,
);

export const sizes = createSelector(
  selectState,(state: fromReducer.State) => state.sizes
);

export const selectedSize = createSelector(
  selectState, (state: fromReducer.State) => state.selectedSize
);

export const isLoading = createSelector(
  selectState, (state: fromReducer.State) => state.isLoading
);
