<div class="container-fluid mt-3">
  <div class="row d-flex justify-content-center">
    <div class="col-md-6 mb-2">
      <div class="panel">
        <div class="panel-body table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الإسم</th>
                <th scope="col">التصنيفات الفرعية</th>
                <th scope="col" class="text-end">
                  <a [routerLink]="['create/category']" data-tip="إضافة تصنيف جديد">
                    <span class="bi bi-plus-circle"></span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let category of categories; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ category.name }}</td>
                <td>
                  <span
                    class="me-2"
                    *ngFor="let subCategory of category.subCategories"
                  >
                    {{ subCategory.name }}
                  </span>
                </td>
                <td>
                  <ul class="action-list">
                    <li>
                      <a [routerLink]="['edit/category/' + category.id]" data-tip="تعديل">
                        <i class="bi bi-pencil-square"></i
                      ></a>
                    </li>
                    <li>
                      <a data-tip="حذف"
                        ><i (click)="deleteCategory(category.id)" class="bi bi-trash-fill"></i
                      ></a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
