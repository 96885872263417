<div
  class="container d-flex align-items-center justify-content-center"
  style="min-height: 100vh"
>
  <div class="card" style="width: 350px">
    <div class="card-body">
      <div class="d-flex justify-content-between mb-4">
        <img class="logo" src="../../../assets/images/Logo1.png" alt="logo..">
      </div>

      <form (submit)="onSubmit()" [formGroup]="loginForm">
        <div class="form-group mb-2">
          <div class="d-flex justify-content-between align-items-center">
            <input
              [ngClass]="{
                'is-invalid':
                  loginForm.get('email')?.invalid &&
                  loginForm.get('email')?.touched
              }"
              type="email"
              class="form-control text-end"
              formControlName="email"
            />
            <label class="text-end" for="email">:الإيميل</label>
          </div>

          <div
            *ngIf="
              loginForm.get('email')?.invalid && loginForm.get('email')?.touched
            "
            class="text-danger"
          >
            <div *ngIf="loginForm.get('email')?.hasError('required')">
              يرجى ادخال الإيميل
            </div>
            <div *ngIf="loginForm.get('email')?.hasError('email')">
              يرجى ادخال ايميل صحيح
            </div>
          </div>
        </div>
        <div class="form-group mb-2">
          <div class="d-flex justify-content-between align-items-center">
            <input
              [ngClass]="{
                'is-invalid':
                  loginForm.get('password')?.invalid &&
                  loginForm.get('password')?.touched
              }"
              type="password"
              class="form-control text-end"
              formControlName="password"
            />
            <label class="text-end" for="password">:كلمة المرور</label>
          </div>
          <div
            *ngIf="
              loginForm.get('password')?.invalid &&
              loginForm.get('password')?.touched
            "
            class="text-danger"
          >
            <div *ngIf="loginForm.get('password')?.hasError('required')">
              يرجى ادخال كلمة المرور
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group mt-2 row justify-content-center">
          <button class="btn btn-sm btn-danger">تسجيل الدخول</button>
        </div>
      </form>
    </div>
  </div>
</div>
