<form (submit)="onSubmit()" [formGroup]="orderForm">
  <div class="form-group mb-2">
    <div class="d-flex justify-content-between align-items-center">
      <input
        [ngClass]="{
          'is-invalid':
            orderForm.get('name')?.invalid && orderForm.get('name')?.touched
        }"
        type="text"
        class="form-control"
        formControlName="name"
        dir="rtl"
      />
      <label for="name">:الإسم</label>
    </div>

    <div
      *ngIf="orderForm.get('name')?.invalid && orderForm.get('name')?.touched"
      class="text-danger"
    >
      <div *ngIf="orderForm.get('name')?.hasError('required')">
        يرجى ادخال الإسم
      </div>
      <div *ngIf="orderForm.get('name')?.hasError('minlength')">
        يجب ان يكون الإسم من حرفين او اكثر
      </div>
    </div>
  </div>
  <div class="form-group mb-2">
    <div class="d-flex justify-content-between align-items-center">
      <input
        [ngClass]="{
          'is-invalid':
            isSubmitted &&
            orderForm.get('number')?.invalid &&
            orderForm.get('number')?.touched
        }"
        type="text"
        class="form-control text-end"
        formControlName="number"
      />
      <label for="number">:الهاتف</label>
    </div>
    <div
      *ngIf="
        isSubmitted &&
        orderForm.get('number')?.invalid &&
        orderForm.get('number')?.touched
      "
      class="text-danger"
    >
      <div *ngIf="isSubmitted && orderForm.get('number')?.hasError('required')">
        يرجى ادخال رقم الهاتف
      </div>
      <div *ngIf="isSubmitted && orderForm.get('number')?.hasError('pattern')">
        يرجى ادخال رقم صحيح
      </div>
    </div>
  </div>
  <div class="form-group mb-2">
    <div class="d-flex justify-content-between align-items-center">
      <input
        [ngClass]="{
          'is-invalid':
            orderForm.get('city')?.invalid &&
            orderForm.get('city')?.touched
        }"
        type="text"
        class="form-control"
        dir="rtl"
        formControlName="city"
      />
      <label for="city">:المدينة</label>
    </div>
    <div
      *ngIf="
        orderForm.get('city')?.invalid && orderForm.get('city')?.touched
      "
      class="text-danger"
    >
      <div *ngIf="orderForm.get('city')?.hasError('required')">
        يرجى ادخال المدينة
      </div>
    </div>
  </div>
  <div class="form-group mb-2">
    <div class="d-flex justify-content-between align-items-center">
      <input
        [ngClass]="{
          'is-invalid':
            orderForm.get('address')?.invalid &&
            orderForm.get('address')?.touched
        }"
        type="text"
        class="form-control"
        dir="rtl"
        formControlName="address"
      />
      <label for="address">:العنوان</label>
    </div>
    <div
      *ngIf="
        orderForm.get('address')?.invalid && orderForm.get('address')?.touched
      "
      class="text-danger"
    >
      <div *ngIf="orderForm.get('address')?.hasError('required')">
        يرجى ادخال العنوان
      </div>
    </div>
  </div>
  <div class="form-group text-end mt-3">
    <label for="notes">:ملاحظات</label>
    <textarea type="text" class="form-control" dir="rtl" formControlName="notes">
    </textarea>
  </div>
  <hr />
  <div class="form-group mt-2 row justify-content-center">
    <button class="btn btn-sm btn-danger" [disabled]="isDisabled">
      تأكيد الطلب
    </button>
  </div>
</form>
