import { createAction, props } from '@ngrx/store';
import { CategoryModel } from '../models/categoryModel';
import { HttpErrorResponse } from '@angular/common/http';

export const loadCategories = createAction('Load Categories');
export const loadCategoriesSuccess = createAction(
  'Load Categories Success',
  props<{ categories: any[] }>()
);
export const CreateCategory = createAction(
  'Create Category',
  props<{ category: CategoryModel }>()
);
export const CreateCategorySuccess = createAction(
  'Create Category Success',
  props<{ category: any }>()
);
export const CreateCategoryFailure = createAction(
  'Create Category Failure',
  props<{ error: any }>()
);
export const loadSelectedCategory = createAction(
  'Load Selected Category',
  props<{ id: number }>()
);
export const LoadSelectedCategorySuccess = createAction(
  'Load Selected Category Success',
  props<{ category: any }>()
);
export const LoadSelectedCategoryFailure = createAction(
  'Load Selected Category Failure',
  props<{ error: HttpErrorResponse }>()
);
export const updateCategory = createAction(
  'Update Category',
  props<{ category: CategoryModel }>()
);
export const UpdateCategorySuccess = createAction('Update Category Success');
export const UpdateCategoryFailure = createAction(
  'Update Category Failure',
  props<{ error: any }>()
);
export const DeleteCategory = createAction(
  'Delete Category',
  props<{ id: number }>()
);

export const DeleteCategorySuccess = createAction(
  'Delete Category Success',
  props<{ id: number }>()
);

export const DeleteCategoryFailure = createAction(
  'Delete Category Failure',
  props<{ error: any }>()
);
