import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, filter } from 'rxjs';
import { AlertTypes } from 'src/app/enums/alertTypes';
import { AlertService } from 'src/app/services/alert.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  public isSelectedProductLoading$: Observable<boolean> = this.productsService.isSelectedProductLoading$
  public isProductsLoading$: Observable<boolean> = this.productsService.isLoading$
  public selectedProduct: any;
  public selectedProductDetail: any;
  public selectedSize: any;
  public availableSizes: any[] = [];
  public quantity: number = 1;
  public similarProducts: any[] = [];
  public selectedProductId: number = 0;
  public chunkSize: number = 4;
  public columnClass: string = '';
  public category: any;
  public description: string = '';

  private subscriptions: Subscription[] = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.chunkSize = this.getChunkSize();
  }

  constructor(
    private productsService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.onResize()
    this.activatedRoute.params.subscribe((params) => {
      this.selectedProductId = params['id'];
      this.productsService.SelectProduct(this.selectedProductId);
    });

    this.subscriptions.push(this.productsService.selectedProduct$.pipe(filter(x => !!x?.name)).subscribe((product) => {
      this.productsService.loadProducts(product.subCategory.id, 0, null,false);
      this.selectedProduct = product;
      this.category = product.subCategory.category
      this.selectedProductDetail = this.selectedProduct.details[0];
      this.availableSizes = this.selectedProductDetail.sizes;
    }));

    this.subscriptions.push(this.productsService.products$.subscribe((products) => {
      this.similarProducts = products.filter(
        (p) => p.id != this.selectedProductId
      );
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public addToCart() {
    if(!this.selectedSize)
    this.alertService.showAlert('يجب اختيار حجم', AlertTypes.danger, 2000);

    if (this.selectedSize.count < this.quantity) {
      this.alertService.showAlert(
        'لا يمكن طلب' + ' ' +
          this.quantity + ' ' +
          'الكمية المتوفرة' + ' ' +
          this.selectedSize.count,
          AlertTypes.danger,
          2000
      );
      return;
    }
    this.cartService.addToCart(
      this.selectedProductDetail,
      this.selectedProduct,
      this.selectedProduct.price,
      this.quantity,
      this.selectedSize
    );
    this.cartService.getCartCount();
    this.alertService.showAlert('تمت اضافة المنتج الى السلة بنجاح', AlertTypes.success, 1500)
  }

  public changeImage(image: any, src: string) {
    image.src = 'data:image/png;base64,' + src;
  }

  public onColorChange() {
    this.availableSizes = this.selectedProductDetail.sizes;
    this.selectedSize = null;
  }

  public setDescription(descreiption: string){
    this.description = descreiption
  }

  private getChunkSize(): number {
    const width = window.innerWidth;


    if (width < 768) {
      this.columnClass = 'col-6'
      return 2;
    } else if (width < 1250) {
      this.columnClass = 'col-md-4'
      return 3;
    } else {
      this.columnClass = 'col-md-3'
      return 4;
    }
  }
}
