import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs';
import { Collections } from 'src/app/enums/collections';
import { AdsService } from 'src/app/services/ads.service';
import { CartService } from 'src/app/services/cart.service';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public ads: string[] = [];
  public categories: any[] = [];
  public collectionCategories: any[] = [];
  public collections: string[] = [];
  public cartCount$ = this.cartService.cartCount$

  constructor(
    private categoryService: CategoryService,
    private cartService: CartService,
    private adsService: AdsService
  ) {}

  ngOnInit(): void {
    this.adsService.loadAd()
    this.categoryService.loadCategories();

    this.adsService.ads$.pipe(filter(x => !!x),take(1)).subscribe(
      (ad) => {
        console.log(ad)
        this.ads = ad.ads}
    );

    this.categoryService.categories$.pipe(filter(x => !!x?.length),take(1)).subscribe(
      (categories) => (this.categories = categories)
    );

    this.collections = Object.values(Collections);
    this.cartService.getCartCount()
  }

  public setCollectionCategories(collection: string) {
    this.collectionCategories = this.categoryService.filterCategoriesByCollection(this.categories, collection);
  }
}
