import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/category.reducers'

export const selectState = createFeatureSelector<fromReducer.State>(
  fromReducer.categoryReducerFeatureKey,
);

export const categories = createSelector(
  selectState,(state: fromReducer.State) => state.categories
);

export const selectedCategory = createSelector(
  selectState, (state: fromReducer.State) => state.selectedCategory
);

export const isLoading = createSelector(
  selectState, (state: fromReducer.State) => state.isLoading
);
