import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, filter, map, of, take } from 'rxjs';
import { State } from '../reducers/sizes.reducer';
import * as userActions from '../actions/user.actions';
import { LoginModel } from '../models/loginModel';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser$ = new BehaviorSubject<any>(null);

  constructor(private store: Store<State>) {}

  public login(model: LoginModel) {
    this.store.dispatch(userActions.Login({ loginModel: model }));
  }

  public loadUser(token: string) {
    this.store.dispatch(userActions.LoadUser({ token }));
  }
}
