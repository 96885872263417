import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css'],
})
export class AdminPanelComponent implements OnInit {
  public currentUser: any;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.loadCurrentUser();
    this.userService.currentUser$
      .pipe(filter((user) => user !== null),take(1))
      .subscribe((user) => this.currentUser = user);
  }

  private loadCurrentUser() {
    const token = localStorage.getItem('token');
    if (token) {
      this.userService.loadUser(token);
    } else {
      this.router.navigate(['myAdmin/login']);
    }
  }
}
