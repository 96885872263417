import * as userActions from '../actions/user.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { AlertTypes } from '../enums/alertTypes';
import { HttpUserService } from '../http-services/http-user.service';
import { UserService } from '../services/user.service';

@Injectable()
export class UserEffects {
  public constructor(
    private actions$: Actions,
    private service: HttpUserService,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService
  ) {}

  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.Login),
      switchMap((action) =>
        this.service.login(action.loginModel).pipe(
          map((jwt) => {
            localStorage.setItem('token', jwt);
            this.router.navigate(['/adminPanel/orders']);
            return userActions.LoginSuccess({ jwt });
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );

  public loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LoadUser),
      switchMap((action) =>
        this.service.loadUser(action.token).pipe(
          tap((response) => {
            this.userService.currentUser$.next(response);           
          }),
          catchError((error) =>{
            this.router.navigate(['myAdmin/login'])
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          }
          )
        )
      )
    ),
    {dispatch: false}
  );
}
