import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { SizeModel } from '../models/sizeModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpSizesService {
  private baseUrl = environment.apiBaseUrl
  constructor(private http: HttpClient) {}

  public getAllSizes(): Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl + '/sizes')
  }
  
  public createSize(model: SizeModel){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(this.baseUrl + '/sizes/create', model, {headers})
  }
}
