import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryModel } from '../models/categoryModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpCategoryService { 
  private baseUrl = environment.apiBaseUrl;
  
  constructor(private http: HttpClient) {}

  public getAllCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + '/categories');
  }

  public createCategory(model: CategoryModel) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(this.baseUrl + '/categories/create', model, {headers});
  }

  public getCategoryById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/categories/' + id);
  }

  public updateCategory(model: CategoryModel){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.put(this.baseUrl + '/categories/update', model, {headers})
  }

  public deleteCategory(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.delete<any>(this.baseUrl + '/categories/' + id + '/delete', {headers});
  }
}
