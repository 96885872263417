import { createReducer, on } from '@ngrx/store';
import * as ordersActions from '../actions/orders.actions';

export const ordersReducerFeatureKey = 'ordersReducer';

export interface State {
  orders: any[],
  isLoading: boolean
}

export const initialState: State = {
  orders: [],
  isLoading: false
};

export const OrdersReducer = createReducer(initialState,
  on(ordersActions.loadOrders, () => ({ ...initialState, isLoading: true})),
  on(ordersActions.loadOrdersSuccess, (state, action) => ({ ...state, orders: action.orders, isLoading: false })),
  on(ordersActions.CreateOrder, (state) => ({...state, isloading: true })),
  on(ordersActions.CreateOrderSuccess, (state) => ({...state, isloading: false })),
  on(ordersActions.UpdateOrderStatusSuccess, (state, action) => ({...state, orders: state.orders.map(o => 
    {
      if(o.id == action.order.id){
      o = {...o, status: action.order.status}
    }
      return o
  })}))
)
