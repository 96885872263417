import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductModel } from '../models/productModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpProductsService {
  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  public getProducts(subCategoryId: number, categoryId: number, collection: string | null, isOnSale: boolean): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/products?subCategory=${subCategoryId}&category=${categoryId}&collection=${collection}&isOnSale=${isOnSale}`);
  }

  public getAllProducts(): Observable<any[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.get<any[]>(`${this.baseUrl}/products/allProducts`, {headers});
  }

  public getProductById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/products/' + id);
  }

  public createProduct(model: ProductModel){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(this.baseUrl + '/products/create', model, {headers})
  }

  public deleteProduct(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.delete<any>(this.baseUrl + '/products/' + id + '/delete', {headers});
  }

  public updateProduct(model: ProductModel){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.put(this.baseUrl + '/products/update', model, {headers})
  }
}
