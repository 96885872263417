import { createAction, props } from "@ngrx/store";
import { LoginModel } from "../models/loginModel";

export const Login = createAction(
    'Login',
    props<{ loginModel: LoginModel }>()
  );
  export const LoginSuccess = createAction(
    'Login Success',
    props<{ jwt: string }>()
  );
  export const LoginFailure = createAction(
    'Login Failure',
    props<{ error: any }>()
  );
  export const LoadUser = createAction(
    'Load User',
    props<{token: string}>()
  );
