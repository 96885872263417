import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderModel } from 'src/app/models/orderModel';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css'],
})
export class OrderFormComponent implements OnInit {
  public orderForm!: FormGroup;
  public isSubmitted: boolean = false;

  @Input()
  public orderModel: Partial<OrderModel> = {};
  @Input()
  public isDisabled: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.orderForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      number: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      address: ['', [Validators.required]],
      city:['', [Validators.required]],
      notes: ['', []],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.orderForm.invalid) {
      return;
    }
    this.orderService.createOrder(this.getOrderModel());
    this.orderForm.reset();
  }

  private getFormControlValue(formControlName: string) {
    return this.orderForm.controls[formControlName].value;
  }

  private getOrderModel(): OrderModel {
    return {
      ...this.orderModel,
      contactName: this.getFormControlValue('name'),
      phoneNo: this.getFormControlValue('number'),
      address: `${this.getFormControlValue('city')} - ${this.getFormControlValue('address')}`,
      description: this.getFormControlValue('notes'),
    } as OrderModel;
  }
}
