import { Component, OnInit, Output } from '@angular/core';
import { OrderModel } from 'src/app/models/orderModel';
import { ProductDetailOrderModel } from 'src/app/models/productDetailOrderModel';
import { ProductDetailCartModel } from 'src/app/models/productDetailCartModel';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit {
  public products: ProductDetailCartModel[] = [];
  public totalPrice$ = this.cartService.totalPrice$;
  @Output()
  public orderModel: Partial<OrderModel> = {}

  constructor(private cartService: CartService) {}

  ngOnInit(): void {
    this.cartService.loadCart();
    this.cartService.cartProducts$.subscribe(cartProducts => this.products = cartProducts);

    this.cartService.getTotalPrice();

    this.orderModel = {
      productDetails: this.products.map(pd => this.getProductDetailOrderModel(pd))
    };
  }

  public removeItemFromCart(id: number){
    this.cartService.removeProduct(id)

    this.orderModel = {
      productDetails: this.products.map(pd => this.getProductDetailOrderModel(pd))
    };
    this.cartService.getTotalPrice();
    this.cartService.getCartCount();
  }

  private getProductDetailOrderModel(productDetail: ProductDetailCartModel): ProductDetailOrderModel{
    return {
      productDetailId:productDetail.id,
      sizeId: productDetail.size.id,
      quantity: productDetail.quantity
    } as ProductDetailOrderModel
  }
}
