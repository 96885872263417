import { createReducer, on } from '@ngrx/store';
import * as adActions from '../actions/ads.actions';

export const adReducerFeatureKey = 'adReducer';

export interface State {
  ad: any,
  isLoading: boolean
}

export const initialState: State = {
  ad: null,
  isLoading: false
};

export const AdReducer = createReducer(initialState,
  on(adActions.loadAd, () => ({ ...initialState, isLoading: true})),
  on(adActions.loadAdSuccess, (state, action) => ({ ...state, ad: action.ad, isLoading: false })),
  on(adActions.CreateAd, (state) => ({...state, isloading: true })),
  on(adActions.CreateAdSuccess, (state, action) => ({...state, ad:[...state.ad, action.ad] , isloading: false })),
)
