import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../reducers/category.reducers';
import * as fromSelector from '../selectors/category.selectors'
import * as categoryActions from '../actions/category.actions'
import { CategoryModel } from '../models/categoryModel';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  public categories$: Observable<any> = this.store.select(fromSelector.categories);
  public selectedCategory$: Observable<any> = this.store.select(
    fromSelector.selectedCategory
  );

  constructor(private store: Store<State>) { }

  public loadCategories(){
    this.store.dispatch(categoryActions.loadCategories());
  }

  public createCategory(category: CategoryModel){
    this.store.dispatch(categoryActions.CreateCategory({category}));
  }

  public filterCategoriesByCollection(categories: any[], collection: string | null): any[]{
    return categories.filter((x) => x.collection == collection)
  }

  public loadSelectedCategory(id: number){
    this.store.dispatch(categoryActions.loadSelectedCategory({id}));
  }

  public updateCategory(category: CategoryModel) {
    this.store.dispatch(categoryActions.updateCategory({ category }));
  }

  public deleteCategory(id: number){
    this.store.dispatch(categoryActions.DeleteCategory({id}))
  }
}
