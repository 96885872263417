import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, filter, take } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { AlertTypes } from 'src/app/enums/alertTypes';
import { ProductDetailsModel } from 'src/app/models/ProductDetailsModel';
import { ProductDetailsSizeModel } from 'src/app/models/productDetailsSizeModel';
import { AlertService } from 'src/app/services/alert.service';
import { SizesService } from 'src/app/services/sizes.service';

@Component({
  selector: 'app-product-details-form',
  templateUrl: './product-details-form.component.html',
  styleUrls: ['./product-details-form.component.css'],
})
export class ProductDetailsFormComponent implements OnInit {
  public detailModel: ProductDetailsModel = { sizes: [], images: [] };
  public productDetailForm!: FormGroup;
  public sizes: any[] = [];
  public selectedSizeId: number = 1;
  public count: number = 0;
  public images: string[] = [];
  public isCountInputTouched: boolean = false;
  public subscriptions: Subscription[] = []

  constructor(private ds: DataService, private sizeService: SizesService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.sizeService.loadSizes();
    this.subscriptions.push(
    this.sizeService.sizes$.pipe(filter(x => !!x?.length)).subscribe((sizes) => (this.sizes = sizes)));
  }

  public addSize() {
    const model = {
      sizeId: this.selectedSizeId,
      count: this.count,
    } as ProductDetailsSizeModel;

    if(this.detailModel.sizes.find(s => s.sizeId == this.selectedSizeId)){
      this.alertService.showAlert('لا يمكن اضافة حجم موجود مسبقا', AlertTypes.danger, 2000);
      return;
    }


    this.detailModel.sizes.push(model);
    this.selectedSizeId = 1;
    this.count = 0;
    this.isCountInputTouched = false
  }

  public onImageChange(event: any) {
    const file: File = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = btoa(reader.result as string);
      this.images.push(base64String);
    };

    reader.readAsBinaryString(file);
  }

  public onDetailSubmit() {
    if(this.images.length == 0)
      return
    
    this.detailModel.images = this.images;
    this.ds.sendData(this.detailModel);
    this.detailModel = {
      sizes: [],
      images: [],
    };
    this.images = [];
  }

  public getSizeName(sizeId: number) {
    const size = this.sizes.find((s) => s.id == sizeId);
    return size.name;
  }

  public removeImage(index: number) {
    this.images = this.images
      .slice(0, index)
      .concat(this.images.slice(index + 1));
  }
  
  public removeSize(index: number) {
    this.detailModel.sizes = this.detailModel.sizes
      .slice(0, index)
      .concat(this.detailModel.sizes.slice(index + 1));
  }

  public onInputBlur(){
    this.isCountInputTouched = true
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
