import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdModel } from '../models/adModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpAdService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {}

  public getAllAds(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + '/ads');
  }

  public createAd(model: AdModel) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(this.baseUrl + '/ads/create', model, {headers});
  }
}
