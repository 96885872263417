import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, filter, take } from 'rxjs';
import { OrderStatuses } from 'src/app/enums/orderStatus';
import { OrderStatusModel } from 'src/app/models/orderStatusModel';
import { OrderService } from 'src/app/services/order.service';
import { SizesService } from 'src/app/services/sizes.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css'],
})
export class OrdersListComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean> = this.orderService.isLoading$;
  public orders: any[] = [];
  public orderStatus: string = '';
  public orderStatuses: string[] = [];
  public status: any;
  public filteredOrders: any[] = [];
  public selectedDetails: any[] = []
  public sizes: any[] = []
  public searchedName: string = ''

  private subscriptions: Subscription[] = [];

  constructor(private orderService: OrderService, private sizeService: SizesService) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.sizeService.loadSizes();
    this.orderService.loadOrders();
    this.subscriptions.push(this.orderService.orders$.pipe(filter(x => !!x?.length)).subscribe((orders) => {
      this.orders = orders;
      this.filteredOrders = orders;
    }));
    this.orderStatuses = Object.values(OrderStatuses);
    this.sizeService.sizes$.pipe(filter(x => !!x?.length),take(1)).subscribe((sizes) => {
      this.sizes = sizes;
    });
  }

  public onOrderStatusFilter(event: any) {
    if (event.target.value == 0) return (this.filteredOrders = this.orders);

    return (this.filteredOrders = this.orders.filter(
      (o) => o.status == event.target.value
    ));
  }

  public onOrderStatusChange(event: any) {
    this.status = Number(event.target.value);
  }

  public changeSubmit(orderId: number) {
    if(!this.status)
      return;
    const orderModel = {
      orderId,
      status: this.status,
    } as OrderStatusModel;

    this.orderService.updateOrderStatus(orderModel);
  }

  setSelectedDetailModel(details: any[]) {
    this.selectedDetails = details;
  }

  public getSizeName(sizeId: number){
    const size = this.sizes.find(s => s.id == sizeId)
    return size.name
  }

  public onSearchName(event: any){
    return (this.filteredOrders = this.orders.filter(
      (o) => o.contactName.includes( event.target.value)
    ));
  }
}
