<div class="container-fluid px-0">
  <div
    id="adsCarousel"
    class="carousel slide"
    data-bs-ride="carousel"
    style="background-color: rgb(246, 237, 241)"
  >
    <div class="carousel-inner">
      <div
        *ngFor="let ad of ads; let i = index"
        class="carousel-item"
        [class.active]="i === 0"
      >
        <p class="carousel-text" style="font-weight: bold">{{ ad }}</p>
      </div>
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-sm navbar-light border-bottom border-top py-0">
  <div class="container-fluid pe-0">
    <div class="d-flex align-items-center">
      <a class="navbar-brand" [routerLink]="['/home']">
        <img class="logo" src="../../../assets/images/Logo1.png" alt="logo.." />
      </a>
    </div>
    <div class="d-flex ms-auto me-3">
      <a [routerLink]="['/cart']">
        <i class="bi bi-cart position-relative" style="color: orangered; font-size: 20px">
          <span *ngIf="(cartCount$ | async) !== 0" class="position-absolute start-50 translate-middle badge rounded-pill" style="font-size: 12px; color: orangered">{{ cartCount$ | async }}</span>
        </i>
      </a>
    </div>
    <button
      class="navbar-toggler me-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

  <div class="collapse navbar-collapse text-end me-3" id="navbarSupportedContent">
    <ul class="navbar-nav mb-lg-0">
      <li class="nav-item h6">
        <a class="nav-link active" [routerLink]="['/contactus']" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">تواصل معنا</a>
      </li>
    </ul>
  </div>
</nav>

<ul class="nav nav-tabs px-2">
  <li class="nav-item dropdown" *ngFor="let collection of collections">
    <a
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-expanded="false"
      style="color: black; text-decoration: underline"
      (mouseover)="setCollectionCategories(collection)"
      >{{ collection }}</a
    >
    <ul class="dropdown-menu">
      <li>
        <a
          class="dropdown-item"
          *ngFor="let collectionCategory of collectionCategories"
          [routerLink]="['/products']"
          [queryParams]="{
            category: collectionCategory.id,
            collection: collectionCategory.collection
          }"
          >{{ collectionCategory.name }}</a
        >
      </li>
    </ul>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      [routerLink]="['/products']"
      [queryParams]="{ isOnSale: true }"
      style="color: black; text-decoration: underline"
      >التنزيلات</a
    >
  </li>
</ul>
