import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { OrderModel } from "../models/orderModel"
import { OrderStatusModel } from "../models/orderStatusModel"


export const loadOrders = createAction('Load Orders')
export const loadOrdersSuccess = createAction('Load Orders Success', props<{ orders: any[] }>())
export const loadOrdersFailure = createAction('Load Orders Failure', props<{ error: HttpErrorResponse }>())

export const CreateOrder = createAction('Create Order', props<{order: OrderModel}>())
export const CreateOrderSuccess = createAction('Create Order Success')
export const CreateOrderFailure = createAction('Create Order Failure', props<{ error: any }>())

export const UpdateOrderStatus = createAction('Update Order Status', props<{orderStatus: OrderStatusModel}>())
export const UpdateOrderStatusSuccess = createAction('Update Order Status Success', props<{order: any}>())
export const UpdateOrderStatusFailure = createAction('Update Order Status Failure', props<{ error: any }>())