import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../reducers/sizes.reducer';
import * as fromSelector from '../selectors/sizes.selectors'
import * as sizeActions from '../actions/size.actions'
import { SizeModel } from '../models/sizeModel';

@Injectable({
  providedIn: 'root'
})
export class SizesService {
  public sizes$: Observable<any> = this.store.select(fromSelector.sizes);

  constructor(private store: Store<State>) { }

  public loadSizes(){
    this.store.dispatch(sizeActions.loadSizes());
  }

  public createSize(size: SizeModel){
    this.store.dispatch(sizeActions.CreateSize({size}))
  }
}
