import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  public categories: any[] = []
  public subscriptions: Subscription[] = []

  constructor(private categoryService: CategoryService) { }

  ngOnInit(): void {
    this.categoryService.loadCategories();
    this.subscriptions.push(
    this.categoryService.categories$.pipe(filter(x => !!x?.length)).subscribe((categories) => this.categories = categories))
  }

  public deleteCategory(id: number){
    this.categoryService.deleteCategory(id)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
