import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersListComponent } from './components/orders-list/orders-list.component';
import { CartComponent } from './components/cart/cart.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryFormComponent } from './components/forms/category-form/category-form.component';
import { ProductDetailsFormComponent } from './components/forms/product-details-form/product-details-form.component';
import { ProductFormComponent } from './components/forms/product-form/product-form.component';
import { SizeFormComponent } from './components/forms/size-form/size-form.component';
import { HomeComponent } from './components/home/home.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductsListComponent } from './components/Products/products-list/products-list.component';
import { AdminProductListComponent } from './components/admin-product-list/admin-product-list.component';
import { LoginComponent } from './components/login/login.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { AppPanelComponent } from './components/app-panel/app-panel.component';
import { AdsFormComponent } from './components/forms/ads-form/ads-form.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '', component: AppPanelComponent, children:[
    {path: 'home', component: HomeComponent},
    {path: 'products', component: ProductsListComponent},
    {path: 'products/:id/details', component: ProductDetailsComponent},
    {path: 'cart', component: CartComponent},
  ]},
  {path: 'myAdmin/login', component: LoginComponent},
  {path: 'adminPanel', component: AdminPanelComponent, children:[
    {path: 'ads/create', component: AdsFormComponent},
    {path: 'orders', component: OrdersListComponent},
    {path: 'productList', component: AdminProductListComponent},
    {path: 'edit/product/:id', component: ProductFormComponent, children:[
      {path: 'productDetail', component: ProductDetailsFormComponent,children:[
      {path: 'size', component: SizeFormComponent}
      ]}
    ]},
    {path: 'create/product', component: ProductFormComponent, children:[
      {path: 'productDetail', component: ProductDetailsFormComponent,children:[
      {path: 'size', component: SizeFormComponent}
      ]}
    ]},
    {path: 'categories', component: CategoriesComponent, children:[
      {path: 'create/category', component: CategoryFormComponent},
      {path: 'edit/category/:id', component: CategoryFormComponent}
    ]},
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
