<div class="card form-card" style="width: 250px;">
  <div class="card-body">
    <h6 class="card-title text-end white-text">إضافة حجم جديد</h6>
    <div>
      <label class="visually-hidden">حجم</label>
      <input
        [(ngModel)]="sizeName"
        type="text"
        class="form-control mb-2"
        placeholder="حجم"
        dir="rtl"
      />
      <hr>
      <div class="row justify-content-center">
        <button (click)="addSize()" class="btn btn-outline-light btn-sm">
          إضافة حجم
        </button>
      </div>
    </div>
  </div>
</div>
