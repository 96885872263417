<div class="container-fluid mt-3">
  <div class="d-flex" style="width: 1500px">
    <div style="width: 400px">
      <div class="card form-card me-2">
        <div class="card-body">
          <h6 class="card-title text-end white-text">إضافة منتج جديد</h6>
          <form [formGroup]="productForm" (submit)="createProduct()">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="أدخل إسم المنتج"
                formControlName="name"
                dir="rtl"
              />
              <label for="name">إسم المنتج</label>
              <div
                *ngIf="
                  productForm.get('name')?.invalid &&
                  productForm.get('name')?.touched
                "
                class="text-warning"
              >
                إسم المنتج مطلوب
              </div>
            </div>
            <div class="form-floating mb-3">
              <input
                type="number"
                class="form-control"
                id="price"
                placeholder="أدخل السعر"
                formControlName="price"
              />
              <label for="price" class="form-label">السعر</label>
              <div
                *ngIf="
                  productForm.get('price')?.invalid &&
                  productForm.get('price')?.touched
                "
                class="text-warning"
              >
                السعر مطلوب
              </div>
            </div>
            <div class="form-floating mb-3">
              <input
                type="number"
                class="form-control"
                id="salePrice"
                placeholder="أدخل سعر العرض"
                formControlName="salePrice"
              />
              <label for="salePrice" class="form-label">سعر العرض</label>
            </div>
            <div class="mb-3">
              <select class="form-select" (change)="onCollectionChange($event)">
                <option selected>إختر مجموعة</option>
                <option
                  *ngFor="let collection of collections"
                  value="{{ collection }}"
                >
                  {{ collection }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <div class="d-flex">
                <select
                  class="form-select me-2"
                  aria-label="إختر تصنيف"
                  (change)="onCategorySelectionChange($event)"
                >
                  <option selected>إختر تصنيف</option>
                  <option
                    *ngFor="let category of collectionCategories"
                    value="{{ category.id }}"
                  >
                    {{ category.name }}
                  </option>
                </select>
                <select
                  class="form-select"
                  label="إختر تصنيف فرعي"
                  (blur)="onDropdownBlur()"
                  (change)="onSubCategorySelectionChange($event)"
                >
                  <option selected>إختر تصنيف فرعي</option>
                  <option
                    *ngFor="let subCategory of subCategories"
                    value="{{ subCategory.id }}"
                  >
                    {{ subCategory.name }}
                  </option>
                </select>
              </div>
              <div
                *ngIf="isSubCategoryTouched && subCategoryId == 0"
                class="text-warning"
              >
                التصنيف الفرعي مطلوب
              </div>
            </div>
            <div class="mb-3">
              <select class="form-select" (change)="onSeasonSelectionChange($event)">
                <option selected>إختر موسم</option>
                <option *ngFor="let season of seasons" value="{{ season }}">
                  {{ season }}
                </option>
              </select>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="brand"
                placeholder="أدخل الماركة"
                formControlName="brand"
                dir="rtl"
              />
              <label for="brand" class="form-label">الماركة</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="description"
                placeholder="أدخل الوصف"
                formControlName="description"
                dir="rtl"
              />
              <label for="description" class="form-label">الوصف</label>
              <div
                *ngIf="
                  productForm.get('description')?.invalid &&
                  productForm.get('description')?.touched
                "
                class="text-warning"
              >
                الوصف مطلوب
              </div>
            </div>
            <hr />
            <div class="row justify-content-end me-1">
              <button
                type="submit"
                class="btn btn-sm btn-outline-light"
                [disabled]="
                  !productForm.valid || productDetailModels.length == 0
                "
              >
                حفظ المنتج
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div style="width: 500px">
      <div class="panel me-2">
        <div class="panel-body table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الصور</th>
                <th scope="col">الأحجام المتوفرة</th>
                <th scope="col">
                  <span
                    class="bi bi-plus-circle"
                    [routerLink]="['productDetail']"
                  ></span>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pdm of productDetailModels; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <img
                    *ngFor="let img of pdm.images"
                    class="me-2 mb-2"
                    [src]="isEdit ? img : 'data:image/png;base64,' + img"
                    alt="..."
                    style="width: 50px; height: 50px"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#sizesModal"
                    (click)="setSelectedDetailModel(pdm)"
                  >
                    عرض الاحجام
                  </button>
                </td>
                <td>
                  <ul class="action-list">
                    <li>
                      <a data-tip="حذف">
                        <i
                          class="bi bi-trash-fill"
                          (click)="deleteProductDetail(i)"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="width: 700px">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="sizesModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="sizesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="sizesModalLabel">الأحجام المختارة</h6>
        <div class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"
            ><span class="path1"></span><span class="path2"></span
          ></i>
        </div>
      </div>
      <div class="modal-body overlay overlay-block">
        <div class="table-responsive">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الحجم</th>
                <th scope="col">الكمية المتوفرة</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let size of selectedDetailModel.sizes; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ getSizeName(size.sizeId) }}</td>
                <td>{{ size.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
