import { createAction, props } from "@ngrx/store";
import { AdModel } from "../models/adModel";

export const loadAd = createAction('Load Ad');
export const loadAdSuccess = createAction(
  'Load Ad Success',
  props<{ ad: any }>()
);
export const CreateAd = createAction(
  'Create Ad',
  props<{ ad: AdModel }>()
);
export const CreateAdSuccess = createAction(
  'Create Ad Success',
  props<{ ad: any }>()
);
export const CreateAdFailure = createAction(
  'Create Ad Failure',
  props<{ error: any }>()
);
