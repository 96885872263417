<div class="d-flex">
  <div>
    <div class="card form-card me-2" style="width: 400px;">
      <div class="card-body">
        <h6 class="card-title text-end white-text">إضافة موديلات</h6>
        <div>
          <div class="d-flex my-2">
            <div
              *ngFor="let img of images; let i = index"
              class="img-wrap me-2"
            >
              <button
                type="button"
                id="clear"
                class="btn-close"
                aria-label="Close"
                (click)="removeImage(i)"
              ></button>
              <img
                src="data:image/png;base64,{{ img }}"
                alt="..."
                style="width: 50px; height: 50px"
              />
            </div>
          </div>
          <input
            type="file"
            class="form-control mb-2"
            id="image"
            placeholder=""
            (change)="onImageChange($event)"
          />
        </div>
        <div class="mb-3" style="overflow-y: scroll; max-height: 150px">
          <table class="table white-text">
            <thead>
              <tr class="white-text">
                <th scope="col">#</th>
                <th scope="col">الحجم</th>
                <th scope="col">الكمية المتوفرة</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let size of detailModel.sizes; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ getSizeName(size.sizeId) }}</td>
                <td>{{ size.count }}</td>
                <button
                  type="button"
                  id="clear"
                  class="btn-close"
                  aria-label="Close"
                  (click)="removeSize(i)"
                ></button>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex">
          <select
            class="form-select me-2"
            aria-label="Default select example "
            [(ngModel)]="selectedSizeId"
          >
            <option *ngFor="let size of sizes" value="{{ size.id }}">
              {{ size.name }}
            </option>
          </select>

          <input
            type="number"
            [(ngModel)]="count"
            class="form-control me-2"
            (blur)="onInputBlur()"
          />
          <span class="bi bi-plus-circle mt-1" [routerLink]="['size']"></span>
        </div>
        <div *ngIf="isCountInputTouched && count == 0" class="text-warning">
          يجب ان تكون الكمية اكبر من 0
        </div>
        <button
          type="submit"
          class="btn btn-sm btn-outline-light mt-3 text-center"
          (click)="addSize()"
          [disabled]="1 > count"
        >
          إضافة حجم
        </button>
        <hr />
        <div class="row justify-content-center">
          <button
            type="submit"
            class="btn btn-sm btn-outline-light"
            (click)="onDetailSubmit()"
            [disabled]="detailModel.sizes.length == 0 || images.length == 0"
          >
            إضافة موديل
          </button>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 200px;">
    <router-outlet></router-outlet>
  </div>
</div>
