import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderModel } from '../models/orderModel';
import { OrderStatusModel } from '../models/orderStatusModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpOrdersService {
  private baseUrl = environment.apiBaseUrl;
  
  constructor(private http: HttpClient) {}

  public getOrders(): Observable<any[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.get<any[]>(this.baseUrl + '/orders', {headers});
  }

  public getOrderById(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.get<any>(this.baseUrl + '/orders/' + id, {headers});
  }

  public createOrder(model: OrderModel){
    return this.http.post(this.baseUrl + '/orders/create', model)
  }

  public updateOrderStatus(model: OrderStatusModel){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.patch(this.baseUrl + '/orders/changeStatus', model, {headers})
  }
}
