import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest, filter } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css'],
})
export class ProductsListComponent implements OnInit {
  public subscriptions: Subscription[] = [];
  public isLoading$: Observable<boolean> = this.productsService.isLoading$
  public products: any[] = [];
  public collectionCategories: any[] = [];
  public categoryId: number = 0;
  public category: any;
  public subCategory: any;
  public subCategoryId: number = 0;
  public collection: string | null = '';
  public categories: any[] = [];
  public visibleProducts: number = 9;
  public isOnSale: boolean = false;
  public showOverlay = false;

  constructor(
    private productsService: ProductsService,
    private categoryService: CategoryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.categoryService.categories$,
      this.activatedRoute.queryParamMap,
    ])
      .pipe(filter(([categories]) => categories.length > 0))
      .subscribe(([categories, params]) => {
        this.categories = categories;
        this.categoryId = Number(params.get('category'));
        this.subCategoryId = Number(params.get('subCategory'));
        this.isOnSale = Boolean(params.get('isOnSale'));
        this.collection = params.get('collection') || null;
        this.productsService.loadProducts(
          this.subCategoryId,
          this.categoryId,
          this.collection,
          true
        );

        if(this.categoryId){
          this.category = this.categories.find(
            (c: { id: number }) => c.id === this.categoryId
          );
        }
        if(this.subCategoryId){
          this.subCategory = this.category.subCategories.find(
            (sc: { id: number }) => sc.id === this.subCategoryId
          );
        }
        this.collection =
          this.collection === null ? this.category.collection : this.collection;
        this.collectionCategories =
          this.categoryService.filterCategoriesByCollection(
            this.categories,
            this.collection
          );
      });
    this.subscriptions.push(
      this.productsService.products$.subscribe((products) => {
        this.products = products;
      })
    );
  }

  showMore(): void {
    this.visibleProducts += 9;
  }

  toggleOverlay() {
    this.showOverlay = !this.showOverlay;
  }

  closeOverlay() {
    this.showOverlay = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    localStorage.removeItem('collection_categories');
  }

  public onSale(){
    this.products = this.products.filter(p => p.salePrice !== 0)
  }
}
