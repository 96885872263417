import { createReducer, on } from '@ngrx/store';
import * as productsActions from '../actions/products.actions';

export const productsReducerFeatureKey = 'productsReducer';

export interface State {
  products: any[],
  selectedProduct: any,
  isLoading: boolean,
  isSelectedProductLoading: boolean
}

export const initialState: State = {
  products: [],
  selectedProduct: {},
  isLoading: false,
  isSelectedProductLoading: false
};

export const ProductsReducer = createReducer(initialState,
  on(productsActions.loadProducts, (state) => ({ ...state, isLoading: true})),
  on(productsActions.loadProductsSuccess, (state, action) => ({ ...state, products: action.products, isLoading: false })),
  on(productsActions.loadAllProducts, (state) => ({ ...state, isLoading: true})),
  on(productsActions.loadAllProductsSuccess, (state, action) => ({ ...state, products: action.products, isLoading: false })),
  on(productsActions.LoadSelectedProduct, (state) => ({ ...state, isSelectedProductLoading: true })),
  on(productsActions.LoadSelectedProductSuccess, (state, action) => ({ ...state, selectedProduct: action.product, isSelectedProductLoading: false })),
  on(productsActions.createProduct, (state) => ({...state, isloading: true })),
  on(productsActions.CreateProductSuccess, (state) => ({...state, isloading: false })),
  on(productsActions.updateProduct, (state) => ({...state, isloading: true })),
  on(productsActions.UpdateProductSuccess, (state) => ({...state, isloading: false })),
  on(productsActions.DeleteProduct, (state) => ({...state, isloading: true })),
  on(productsActions.DeleteProductSuccess, (state, action) => ({...state, isloading: false, products: state.products.filter(product => product.id !== action.id) })),
)
