import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';
import { HttpAdService } from '../http-services/http-ads.service';
import * as adActions from '../actions/ads.actions';
import { AlertService } from '../services/alert.service';
import { AlertTypes } from '../enums/alertTypes';

@Injectable()
export class AdEffects {
  public constructor(
    private actions$: Actions,
    private service: HttpAdService,
    private alertService: AlertService
  ) {}

  public loadAds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adActions.loadAd),
      switchMap(() =>
        this.service.getAllAds().pipe(
          map((ad) =>
            adActions.loadAdSuccess({ ad })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public createAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adActions.CreateAd),
      switchMap((action) =>
        this.service.createAd(action.ad).pipe(
          map((ad) =>{
            this.alertService.showAlert('تمت اضافة الاعلانات بنجاح', AlertTypes.success, 2000)
            return adActions.CreateAdSuccess({ ad })
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );
}
