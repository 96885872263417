<div class="container-fluid pt-4 background">
  <div class="row justify-content-center">
    <div class="col-md-6 mb-2">
      <div class="card">
        <div class="card-header text-end py-3">
          <h6>محتويات السلة</h6>
        </div>
        <div class="card-body overflow-auto" style="max-height: 380px;">
          <div *ngIf="!products.length">
            <h6 class="mb-3 text-end">لا يوجد محتويات في السلة</h6>
          </div>
          <ng-container *ngIf="products.length">
            <div *ngFor="let product of products" class="container mb-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="card cart-item">
                    <div class="row justify-content-between">
                      <div class="col">
                        <img src="{{ product.images[0] }}" alt="Product Image" class="cart-item-image rounded-start">
                      </div>
                      <div class="col">
                        <div class="card-body text-end">
                          <div class="delete-icon">
                            <a
                              style="cursor: pointer"
                              (click)="removeItemFromCart(product.id)"
                              title="أزل من السلة"
                              ><i class="bi bi-trash3"></i
                            ></a>
                          </div>
                          <div>
                              <h6 class="card-title">{{ product.name }}</h6>
                          </div>
                          <div>
                              <strong class="card-text">{{ product.brand }}</strong>
                          </div>
                          <div>
                              <strong *ngIf="product.salePrice != 0; else elseBlock" class="card-text">{{ product.salePrice | currency: 'ILS'}}</strong>
                              <ng-template #elseBlock>
                                <strong class="card-text">{{ product.price | currency: 'ILS' }}</strong>
                              </ng-template>
                          </div>
                          <div>
                            <strong class="card-text">{{ product.size.name}}</strong>
                            <span class="mb-1 ms-2">:الحجم</span>
                          </div>
                          <div class="d-flex justify-content-end">
                              {{product.quantity}}
                            <span class="mb-1 ms-2">:الكمية</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between mt-2">
            <p>{{ totalPrice$ | async | currency: 'ILS' }}</p>
            <p>المبلغ الكامل</p>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-header text-end py-3">
          <h6>معلومات المستلم</h6>
        </div>
        <div class="card-body px-4">
          <app-order-form [orderModel]="orderModel" [isDisabled]="!products.length"></app-order-form>
        </div>
      </div>
    </div>
  </div>
</div>
