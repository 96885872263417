import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';
import { HttpSizesService } from '../http-services/http-sizes.service';
import * as sizesActions from '../actions/size.actions';
import { AlertService } from '../services/alert.service';
import { AlertTypes } from '../enums/alertTypes';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SizesEffects {
  public constructor(
    private actions$: Actions,
    private service: HttpSizesService,
    private alertService: AlertService
  ) {}

  public loadSizes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sizesActions.loadSizes),
      switchMap(() =>
        this.service.getAllSizes().pipe(
          map((sizes) => sizesActions.loadSizesSuccess({ sizes: sizes })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public createSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sizesActions.CreateSize),
      switchMap((action) =>
        this.service.createSize(action.size).pipe(
          map((size) => {
            this.alertService.showAlert('تمت اضافة الحجم بنجاح', AlertTypes.success, 1000)
            return sizesActions.CreateSizeSuccess({size});
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );
}
