<nav class="navbar navbar-light border-bottom px-2">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/home']">
      <img class="logo" src="../../../assets/images/Logo1.png" alt="logo..">
    </a>
    <div class="d-flex justify-content-between">
      <ul class="nav nav-pills align-items-center" id="menu">
        <li>
          <a [routerLink]="['/adminPanel/ads/create']" class="nav-link">
            <span class="me-2">الاعلانات</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/adminPanel/orders']" class="nav-link">
            <span class="me-2">الطلبات</span>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/adminPanel/productList']"
            data-bs-toggle="collapse"
            class="nav-link"
          >
            <span class="me-2">المنتجات</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/adminPanel/categories']" class="nav-link">
            <span class="me-2">التصنيفات</span>
          </a>
        </li>
        <button class="btn btn-sm btn-outline-danger fs-6 ms-3" (click)="logout()">
          تسجيل الخروج
          <i class="bi bi-box-arrow-right fs-6 ms-1"></i>
        </button>
      </ul>
    </div>
  </div>
</nav>
