<div class="container-fluid mt-3">
  <div class="row justify-content-center h5">
    المنتجات
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-body table-responsive">
          <table class="table align-middle">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th *ngIf="!isSearchOpen; else elseBlock" scope="col">الرقم التعريفي</th>
                <ng-template #elseBlock>
                <th scope="col text-end">
                    <div class="input-group custom-width mb-1">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="id"
                        placeholder="الرقم التعريفي"
                        (change)="onSearchId($event)"
                      />
                    </div>
                  </th>
                </ng-template>
                <th scope="col">الإسم</th>
                <th scope="col">الوصف</th>
                <th scope="col">السعر</th>
                <th scope="col">الماركة</th>
                <th scope="col">الموديلات المتوفرة</th>
                <th scope="col">التصنيف الفرعي</th>
                <th scope="col">
                  <span><i role="button" (click)="changeSearchAppearenc()" class="bi bi-search pe-auto mx-5"></i></span>
                  <a href="adminPanel/create/product" data-tip="إضافة منتج جديد">
                    <span class="bi bi-plus-circle"></span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of filterdProducts; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ product.id }}</td>
                <td class="truncate" title="{{ product.name }}">{{ product.name }}</td>
                <td class="truncate" title="{{ product.description}}">{{ product.description }}</td>
                <td class="truncate" title="{{ product.price }}">{{ product.price }}</td>
                <td class="truncate" title="{{ product.brand}}">{{ product.brand }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#detailsModal"
                    (click)="setSelectedDetailModel(product.details)"
                  >
                    عرض الموديلات
                  </button>
                </td>
                <td>{{ product.subCategory.name }}</td>
                <td>
                  <ul class="action-list">
                    <li>
                      <a [href]="'adminPanel/edit/product/' + product.id" data-tip="تعديل">
                        <i class="bi bi-pencil-square"></i>
                      </a>
                    </li>
                    <li>
                      <a data-tip="حذف"
                        ><i
                          class="bi bi-trash-fill"
                          (click)="deleteProduct(product.id)"
                        ></i
                      ></a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="progress" *ngIf="(isLoading$ | async)">
            <div class="progress-bar bg-secondary progress-bar-striped progress-bar-animated w-100" role="progressbar"></div>
          </div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="detailsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="detailsLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="detailsLabel">الأحجام المختارة</h6>
        <div class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"
            ><span class="path1"></span><span class="path2"></span
          ></i>
        </div>
      </div>
      <div class="modal-body overlay overlay-block">
        <div class="table-responsive">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الموديلات</th>
                <th scope="col">الاحجام</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let detail of selectedDetailModel; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <img
                    *ngFor="let img of detail.images"
                    class="me-2"
                    src="{{ img }}"
                    alt="..."
                    style="width: 50px; height: 50px"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#sizesModal"
                    (click)="setSelectedDetail(detail)"
                  >
                    عرض الاحجام
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="sizesModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="sizesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="sizesModalLabel">الأحجام المختارة</h6>
        <div class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"
            ><span class="path1"></span><span class="path2"></span
          ></i>
        </div>
      </div>
      <div class="modal-body overlay overlay-block">
        <div class="table-responsive">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الحجم</th>
                <th scope="col">الكمية المتوفرة</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let size of selectedDetail?.sizes; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ size.name }}</td>
                <td>{{ size.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
