import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/ads.reducers'

export const selectState = createFeatureSelector<fromReducer.State>(
  fromReducer.adReducerFeatureKey,
);

export const ads = createSelector(
  selectState,(state: fromReducer.State) => state.ad
);

export const isLoading = createSelector(
  selectState, (state: fromReducer.State) => state.isLoading
);
