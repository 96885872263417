import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, filter, take } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Collections } from 'src/app/enums/collections';
import { Seasons } from 'src/app/enums/seasons';
import { ProductDetailsModel } from 'src/app/models/ProductDetailsModel';
import { ProductDetailsSizeModel } from 'src/app/models/productDetailsSizeModel';
import { ProductModel } from 'src/app/models/productModel';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsService } from 'src/app/services/products.service';
import { SizesService } from 'src/app/services/sizes.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css'],
})
export class ProductFormComponent implements OnInit {
  public productForm!: FormGroup;
  public productDetailModels: ProductDetailsModel[] = [];
  public categories: any[] = [];
  public subCategories: any[] = [];
  public subCategoryId: number = 0;
  public selectedDetailModel: Partial<ProductDetailsModel> = {};
  public sizes: any[] = [];
  public id: number = 0;
  public isSubCategoryTouched: boolean = false;
  public collections: string[] = [];
  public selectedSeason: Seasons = 4;
  public seasons: any[] = Object.keys(Seasons).filter((v) => isNaN(Number(v)));
  public collectionCategories: any[] = [];
  public subscriptions: Subscription[] = [];
  public isEdit: boolean = false;
  public isSubCategoryMultiSelectDisabled: boolean = true;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly ds: DataService,
    private readonly categoryService: CategoryService,
    private readonly productService: ProductsService,
    private readonly sizeService: SizesService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.collections = Object.values(Collections);

    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      description: ['', Validators.required],
      price: ['', [Validators.required]],
      salePrice:['0'],
      brand: [''],
    });

    this.activatedRoute.params.pipe(filter(params => 'id' in params ),take(1)).subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.productService.SelectProduct(this.id);
        this.isEdit = true
      }
    });

    if (this.id) {
      this.productService.selectedProduct$.pipe(filter(x => !!x?.name),take(1)).subscribe((product) => {
        this.productForm.controls['name'].setValue(product.name);
        this.productForm.controls['description'].setValue(product.description);
        this.productForm.controls['price'].setValue(product.price);
        this.productForm.controls['brand'].setValue(product.brand);
        this.productForm.controls['salePrice'].setValue(product.salePrice);
        this.mapToProductDetails(product.details);
        this.subCategoryId = product.subCategory.id;
      });
    }
    this.categoryService.loadCategories()
    this.categoryService.categories$.pipe(filter(x => !!x?.length),take(1)).subscribe((categories) => {
      this.categories = categories;
      this.collectionCategories = categories;
      this.subCategories = categories[0].subCategories;
    });
    this.subscriptions.push(
    this.ds.getData().subscribe((x) => this.productDetailModels.push(x)));

    this.subscriptions.push(
    this.sizeService.sizes$.pipe(filter(x => !!x?.length)).subscribe((sizes) => {
      this.sizes = sizes;
    }));
  }

  public createProduct() {
    if(!this.subCategoryId){
      this.isSubCategoryTouched = true
      return;
    }

    const model = {
      id: this.id,
      name: this.productForm.controls['name'].value,
      description: this.productForm.controls['description'].value,
      price: this.productForm.controls['price'].value,
      salePrice: this.productForm.controls['salePrice'].value,
      brand: this.productForm.controls['brand'].value,
      season: this.selectedSeason,
      Details: this.productDetailModels,
      SubCategoryId: this.subCategoryId,
    } as ProductModel;

    if(this.id){
      this.productService.updateProduct(model);
    }
    else{
      this.productService.createProduct(model);
      this.productForm.reset();
      this.productForm.get('salePrice')?.setValue(0);
      this.productDetailModels = [];
    }
  }

  public onCategorySelectionChange(event: any) {
    if (!Number(event.target.value)) {
      return;
    }
    this.subCategories = this.collectionCategories.find(
      (c) => c.id === Number(event.target.value)
    ).subCategories;
    this.isSubCategoryMultiSelectDisabled = false;
  }

  public onCollectionChange(event:any){
    this.collectionCategories = this.categories.filter(
      (c) => c.collection === event.target.value
    )
    this.subCategories = [];
    this.isSubCategoryMultiSelectDisabled = true;
  }

  public onSubCategorySelectionChange(event: any) {
    this.isSubCategoryTouched = true;
    this.subCategoryId = Number(event.target.value);
  }

  public onSeasonSelectionChange(event: any) {
    this.selectedSeason = Number(Seasons[event.target.value]);
  }

  public setSelectedDetailModel(detailModel: ProductDetailsModel) {
    this.selectedDetailModel = detailModel;
  }

  public getSizeName(sizeId: number) {
    const size = this.sizes.find((s) => s.id == sizeId);
    return size.name;
  }

  public mapToProductDetails(entityDetails: any[]) {
    this.productDetailModels = entityDetails.map((detail) => {
      return {
        id: detail.id,
        images: detail.images.map((image: any) => image),
        sizes: detail.sizes.map(
          (size: { id: any; count: any }) =>
            ({
              sizeId: size.id,
              count: size.count,
            } as ProductDetailsSizeModel)
        ),
      } as ProductDetailsModel;
    });
  }

  public deleteProductDetail(detailId: number) {
    this.productDetailModels.splice(detailId, 1);
  }

  public onDropdownBlur() {
    this.isSubCategoryTouched = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
