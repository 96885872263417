import { createReducer, on } from '@ngrx/store';
import * as sizesActions from '../actions/size.actions';

export const sizesReducerFeatureKey = 'sizesReducer';

export interface State {
  sizes: any[],
  selectedSize: any,
  isLoading: boolean
}

export const initialState: State = {
  sizes: [],
  selectedSize: {},
  isLoading: false
};

export const SizesReducer = createReducer(initialState,
  on(sizesActions.loadSizes, () => ({ ...initialState, isLoading: true})),
  on(sizesActions.loadSizesSuccess, (state, action) => ({ ...state, sizes: action.sizes, isLoading: false })),
  on(sizesActions.CreateSize, (state) => ({...state, isloading: true })),
  on(sizesActions.CreateSizeSuccess, (state, action) => ({...state,sizes:[...state.sizes, action.size], isloading: false })),
)
