<div class="container-fluid mt-3">
  <div class="row justify-content-center">
    <div class="card p-2 form-card mt-3" style="width: 40rem">
      <div class="card-body">
        <h6 class="card-title text-end white-text h5">اضافة اعلانات</h6>
        <form [formGroup]="adForm" (ngSubmit)="onSubmit()">
          <div formArrayName="ads">
            <div *ngFor="let ad of getControls(); let i = index">
              <div [formGroupName]="i">
                <div class="form-group mb-2 d-flex">
                  <input
                    type="text"
                    class="form-control me-2"
                    [id]="i"
                    formControlName="adText"
                  />
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-danger"
                    (click)="removeAdField(i)"
                  >
                    <i class="bi bi-trash3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-sm btn-outline-light me-2"
            (click)="addAdField()"
          >
            أضافة
          </button>
          <hr>
          <div class="text-center">
            <button type="submit" class="btn btn-outline-light" [disabled]="!adsArray.length">
              حفظ وتسليم
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
