import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, filter } from 'rxjs';
import { ProductDetailsModel } from 'src/app/models/ProductDetailsModel';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-admin-product-list',
  templateUrl: './admin-product-list.component.html',
  styleUrls: ['./admin-product-list.component.css'],
})
export class AdminProductListComponent implements OnInit {
  public isLoading$: Observable<boolean> = this.productsService.isLoading$;
  public subscriptions: Subscription[] = [];
  public products: any[] = [];
  public selectedDetailModel: Partial<ProductDetailsModel>[] = [];
  public filterdProducts: any[] = []
  public isSearchOpen: boolean = false
  public selectedDetail: any

  constructor(private productsService: ProductsService) {}

  ngOnInit(): void {
    this.productsService.loadAllProducts();

    this.subscriptions.push(
      this.productsService.products$.pipe(filter(x => !!x?.length)).subscribe((products) => {
        this.products = products;
        this.filterdProducts = products
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public deleteProduct(id: number) {
    this.productsService.deleteProduct(id);
  }

  public setSelectedDetailModel(detailModel: ProductDetailsModel[]) {
    this.selectedDetailModel = detailModel;
  }

  public onSearchId(event: any){
    if(event.target.value == '')
      return this.filterdProducts = this.products
      
    return (this.filterdProducts = this.products.filter(
      (p) => p.id == event.target.value
    ));
  }

  public changeSearchAppearenc(){
    !this.isSearchOpen ? this.isSearchOpen = true : this.isSearchOpen = false
  }

  public setSelectedDetail(detail: any){
    this.selectedDetail = detail
  }
}
