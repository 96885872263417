import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdsService } from 'src/app/services/ads.service';

@Component({
  selector: 'app-ads-form',
  templateUrl: './ads-form.component.html'
})
export class AdsFormComponent implements OnInit{
  adForm: FormGroup;
  public adsArray!: FormArray

  constructor(private formBuilder: FormBuilder, private adsService: AdsService) {
    this.adForm = this.formBuilder.group({
      ads: this.formBuilder.array([])
    });
  }
  ngOnInit(): void {
    this.adsArray = this.adForm.get('ads') as FormArray;
  }

  createAdFormGroup(): FormGroup {
    return this.formBuilder.group({
      adText: ['', Validators.required]
    });
  }

  addAdField(): void {
    this.adsArray.push(this.createAdFormGroup());
  }

  removeAdField(index: number): void {
    this.adsArray.removeAt(index);
  }

  getControls() {
    return (this.adForm.get('ads') as FormArray).controls;
  }

  onSubmit() {
    const adModel = {
      ads: this.adForm.value.ads.map((adFormGroup: any) => adFormGroup.adText)
    };

    this.adsService.createAd(adModel)
    this.adForm.reset();
  }


}
