<div id="productDetailsCarousel" class="carousel slide carousel-dark carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div *ngFor="let image of images; let i = index" class="carousel-item" [class.active]="i === 0" data-bs-interval="3000">
      <img src="{{ image }}" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#productDetailsCarousel" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#productDetailsCarousel" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
  <ol class="carousel-indicators">
    <li *ngFor="let image of images; let i = index" data-bs-target="#myCarousel" [attr.data-bs-slide-to]="i" [ngClass]="{'active': i === 0}"></li>
  </ol>
</div>

