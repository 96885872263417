<div class="p-4 footer">
  <div class="row">
    <div class="col-6">
      <strong class="d-block"> زورونا على مواقع التواصل </strong>
      <strong> الاجتماعي واتركو لنا رأيكم </strong>
      <div class="d-flex mt-2">
        <i class="bi bi-facebook me-2 fs-4"></i>
        <i class="bi bi-instagram fs-4"></i>
      </div>
    </div>
    <div class="col-6 text-end">
      <div class="d-flex justify-content-end">
        <strong>فلسطين - الخليل</strong>
        <i class="bi bi-geo-alt ms-2 fs-5"></i>
      </div>
      <strong class="me-4"> صوريف - شارع بيت أمر </strong>
      <div class="d-flex justify-content-end align-items-center mt-2">
        <strong>0599305448</strong>
        <i class="bi bi-telephone ms-2 fs-5"></i>
      </div>
    </div>
  </div>
</div>
