import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../reducers/orders.reducers';
import * as OrderActions from '../actions/orders.actions';
import { OrderModel } from '../models/orderModel';
import { Observable } from 'rxjs';
import * as fromSelector from '../selectors/orders.selectors';
import { OrderStatusModel } from '../models/orderStatusModel';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public orders$: Observable<any> = this.store.select(fromSelector.orders);
  public isLoading$: Observable<any> = this.store.select(fromSelector.isLoading);

  constructor(private store: Store<State>) {}

  public loadOrders() {
    this.store.dispatch(OrderActions.loadOrders());
  }

  public createOrder(order: OrderModel) {
    this.store.dispatch(OrderActions.CreateOrder({ order }));
  }

  public updateOrderStatus(orderStatus: OrderStatusModel) {
    this.store.dispatch(OrderActions.UpdateOrderStatus({ orderStatus }));
  }
}
