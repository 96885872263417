import { Component, OnInit } from '@angular/core';
import { SizeModel } from 'src/app/models/sizeModel';
import { SizesService } from 'src/app/services/sizes.service';

@Component({
  selector: 'app-size-form',
  templateUrl: './size-form.component.html',
  styleUrls: ['./size-form.component.css']
})
export class SizeFormComponent implements OnInit {
  public sizeName: string = ''

  constructor(private sizeService: SizesService) { }

  ngOnInit(): void {
  }

  public addSize(){
    const model =  {
      name: this.sizeName
    } as SizeModel
    this.sizeName = '';
    this.sizeService.createSize(model);
  }
}
