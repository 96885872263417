<div class="container-fluid stripColor">
  <div class="row">
    <div
      *ngIf="!isOnSale"
      class="col-md-3 d-none d-md-block border-end"
      style="max-width: 200px;"
    >
      <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
    </div>

    <div [ngClass]="{'col-md-9': !isOnSale, 'col-md-12': isOnSale, 'hide-sidebar': showOverlay}">
      <div class="row justify-content-between mt-1">
        <div class="col-10 text">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="['/home']">الصفحة الرئيسية</a>
              </li>
              <li *ngIf="isOnSale" class="breadcrumb-item active">
                قسم التنزيلات
              </li>
              <li *ngIf="!isOnSale" class="breadcrumb-item">
                <a [routerLink]="['/products']" [queryParams]="{ collection }"
                  >قسم {{ collection }}</a
                >
              </li>
              <li
                *ngIf="categoryId != 0"
                class="breadcrumb-item"
                aria-current="page"
              >
              <a [routerLink]="['/products']" [queryParams]="{ category: category.id, collection}">{{ category.name }}</a
            >
              </li>
              <li
                *ngIf="subCategoryId != 0"
                class="breadcrumb-item active"
                aria-current="page"
              >
                {{ subCategory?.name }}
              </li>
            </ol>
          </nav>
        </div>
        <div *ngIf="!isOnSale" class="col-2 d-md-none text-end">
          <button class="btn btn-sm" type="button" (click)="toggleOverlay()">
            <i class="bi bi-funnel"></i>
          </button>
        </div>
      </div>
        <div *ngIf="!(isLoading$ | async); else loading" class="row px-5 product-list-row">
          <div [ngClass]="{'col-md-6 col-lg-4': !isOnSale, 'col-md-4 col-lg-3': isOnSale}" class="col-6" *ngFor="let product of products; let i = index" [class.d-none] = "i >= visibleProducts">
            <div class="card bg-transparent mb-3">
              <img
                src="{{ product.details.image }}"
                class="card-img-top rounded-top"
                alt="..."
              />
              <div class="card-body text-end">
                <h5 class="card-title">{{ product.name }}</h5>
                <p class="card-text h6">{{ product.brand }}</p>
                <div class="card-text h5 mb-1" [class.old-price] = "product.salePrice !== 0" style="color: orangered">
                  <a
                    [routerLink]="['/products', product.id, 'details']"
                    class="btn stretched-link"
                  ></a>
                  {{ product.price | currency : "ILS" }}
                </div>
                <div *ngIf="product.salePrice !== 0" class="card-text h5" style="color: orangered">
                  {{ product.salePrice | currency : "ILS" }}
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3 justify-content-center" [class.d-none] = "products.length <= visibleProducts">
            <button class="btn btn-danger" (click)="showMore()">عرض المزيد</button>
          </div>
        </div>
        <div class="row bg-transparent" style="height: 150px;"></div>
        <ng-template #loading>
          <div class="row px-5 justify-content-center">
            <div class="col-md-6 col-lg-4 col-sm-6 mt-5">
              <img class="w-100" src="../../../../assets/images/loading.gif" alt="...">
            </div>
        </div>
        </ng-template>

    </div>

    <div *ngIf="!isOnSale" class="sidebar-overlay text-end" [class.show]="showOverlay">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="toggleOverlay()"
      ></button>
      <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
    </div>
  </div>
</div>

<ng-template #sidebarContent>
  <div
    class="d-flex flex-column text-end px-3 pt-3 min-vh-100"
  >
    <ul
      class="nav nav-pills flex-column mb-sm-auto mb-0 "
      id="menu"
    >
      <li *ngFor="let category of collectionCategories; let i = index">
        <a
          href="#submenu{{ i }}"
          data-bs-toggle="collapse"
          class="nav-link px-0 align-middle dropdown-toggle"
          style="color: black"
        >
          {{ category.name }}
        </a>
        <ul
          class="collapse nav flex-column me-3"
          id="submenu{{ i }}"
          data-bs-parent="#menu"
        >
          <li class="w-100">
            <a
              href="#"
              class="nav-link px-0 ms-2"
              *ngFor="let subCategory of category.subCategories"
              [routerLink]="['/products']"
              [queryParams]="{ subCategory: subCategory.id, category: category.id, collection: category.collection}"
              (click)="closeOverlay()"
              style="color: black"
              >{{ subCategory.name }}</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link px-0 align-middle" (click)="onSale()" style="color: black"
          >التنزيلات</a
        >
      </li>
    </ul>
    <hr />
  </div>
</ng-template>
