import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CategoryEffects } from './effects/Category.effects';
import { HttpClientModule } from '@angular/common/http';
import * as categoryReduction from './reducers/category.reducers';
import * as productsReduction from './reducers/products.reducers';
import * as ordersReduction from './reducers/orders.reducers';
import * as cartReduction from './reducers/cart.reducers';
import * as sizesReduction from './reducers/sizes.reducer';
import * as adsReduction from './reducers/ads.reducers';
import { ProductsListComponent } from './components/Products/products-list/products-list.component'
import { ProductsEffects } from './effects/products.effects';
import { CartComponent } from './components/cart/cart.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { OrderFormComponent } from './components/orders/order-form/order-form.component';
import { HeaderComponent } from './components/header/header.component';
import { OrdersEffects } from './effects/orders.effects';
import { LoginComponent } from './components/login/login.component';
import { OrdersListComponent } from './components/orders-list/orders-list.component';
import { ProductFormComponent } from './components/forms/product-form/product-form.component';
import { ProductDetailsFormComponent } from './components/forms/product-details-form/product-details-form.component';
import { SizeFormComponent } from './components/forms/size-form/size-form.component';
import { DataService } from './data.service';
import { SizesEffects } from './effects/sizes.effect';
import { CategoryFormComponent } from './components/forms/category-form/category-form.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { AdminProductListComponent } from './components/admin-product-list/admin-product-list.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { ChunkPipe } from './custom-pipes/chunk.pipe';
import { AlertService } from './services/alert.service';
import { FooterComponent } from './components/footer/footer.component';
import { UserEffects } from './effects/user.effects';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { AppPanelComponent } from './components/app-panel/app-panel.component';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { AdEffects } from './effects/ads.effects copy';
import { AdsFormComponent } from './components/forms/ads-form/ads-form.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsListComponent,
    CartComponent,
    ProductDetailsComponent,
    OrderFormComponent,
    HeaderComponent,
    LoginComponent,
    OrdersListComponent,
    ProductFormComponent,
    ProductDetailsFormComponent,
    SizeFormComponent,
    CategoryFormComponent,
    CategoriesComponent,
    AdminProductListComponent,
    ImageSliderComponent,
    ChunkPipe,
    FooterComponent,
    AdminPanelComponent,
    AppPanelComponent,
    AdminHeaderComponent,
    AdsFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature(productsReduction.productsReducerFeatureKey, productsReduction.ProductsReducer),
    StoreModule.forFeature(ordersReduction.ordersReducerFeatureKey, ordersReduction.OrdersReducer),
    StoreModule.forFeature(categoryReduction.categoryReducerFeatureKey, categoryReduction.CategoryReducer),
    StoreModule.forFeature(cartReduction.cartReducerFeatureKey, cartReduction.cartReducer),
    StoreModule.forFeature(sizesReduction.sizesReducerFeatureKey, sizesReduction.SizesReducer),
    StoreModule.forFeature(adsReduction.adReducerFeatureKey, adsReduction.AdReducer),
    EffectsModule.forRoot([CategoryEffects, ProductsEffects, OrdersEffects, SizesEffects, UserEffects, AdEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [DataService, AlertService],
  bootstrap: [AppComponent]
})
export class AppModule { }
