import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { HttpCategoryService } from '../http-services/http-category.service';
import * as categoryActions from '../actions/category.actions';
import { AlertService } from '../services/alert.service';
import { AlertTypes } from '../enums/alertTypes';

@Injectable()
export class CategoryEffects {
  public constructor(
    private actions$: Actions,
    private service: HttpCategoryService,
    private router: Router,
    private alertService: AlertService
  ) {}

  public loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.loadCategories),
      switchMap(() =>
        this.service.getAllCategories().pipe(
          map((categories) =>
            categoryActions.loadCategoriesSuccess({ categories: categories })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public createCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.CreateCategory),
      switchMap((action) =>
        this.service.createCategory(action.category).pipe(
          map((category) =>{
            this.alertService.showAlert('تمت اضافة التصنيف بنجاح', AlertTypes.success, 2000)
            return categoryActions.CreateCategorySuccess({ category })
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );

  public loadCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.loadSelectedCategory),
      switchMap((action) => {
        return this.service.getCategoryById(action.id).pipe(
          map((category) =>
            categoryActions.LoadSelectedCategorySuccess({ category })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  public updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.updateCategory),
      switchMap((action) =>
        this.service.updateCategory(action.category).pipe(
          map(() => {
            this.alertService.showAlert('تم تعديل التصنيف بنجاح', AlertTypes.success, 2000)
            return categoryActions.UpdateCategorySuccess()
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );

  public deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.DeleteCategory),
      switchMap((action) => {
        return this.service.deleteCategory(action.id).pipe(
          map(() => {
            this.alertService.showAlert('تم حذف التصنيف بنجاح', AlertTypes.success, 2000)
            return categoryActions.DeleteCategorySuccess({id: action.id})
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        );
      })
    )
  );
}
