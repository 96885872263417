import { Injectable } from '@angular/core';
import { ProductDetailCartModel } from '../models/productDetailCartModel';
import { SizeModel } from '../models/sizeModel';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public cartItemsList: ProductDetailCartModel[] = [];
  public cartCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public cartProducts$: BehaviorSubject<ProductDetailCartModel[]> = new BehaviorSubject<ProductDetailCartModel[]>([]);

  constructor() {}

  public saveCart() {
    localStorage.setItem('cart_items', JSON.stringify(this.cartItemsList));
  }

  public addToCart(
    productDetail: any,
    product: any,
    price: number,
    quantity: number,
    size: any
  ) {
    const productDetailCartModel = this.getProductDetailCartModel(
      productDetail,
      product,
      price,
      quantity,
      size
    );
    this.cartItemsList.push(productDetailCartModel);
    this.saveCart();
  }

  public loadCart(){
    this.cartItemsList = JSON.parse(localStorage.getItem('cart_items') as any) || [];
    this.cartProducts$.next(this.cartItemsList);
  }

  public isProductInCart(product: any){
    return this.cartItemsList.findIndex((x: any) => x.id === product.id) > -1;
  }

  public removeProduct(id: number){
    const index = this.cartItemsList.findIndex((x: any) => x.id === id);
    if(index > -1){
      this.cartItemsList.splice(index, 1);
      this.saveCart();
    }
  }

  public clearProducts(){
    localStorage.removeItem('cart_items')
    this.cartProducts$.next([]);
    this.cartCount$.next(0);
    this.totalPrice$.next(0);
    this.cartItemsList = []
  }

  public getTotalPrice() {
    let totalPrice = 0;
    this.cartItemsList.forEach((i) => (totalPrice += (i.salePrice == 0 ? i.price: i.salePrice) * i.quantity));
    this.totalPrice$.next(totalPrice);
  }

  public getCartCount() {
    this.loadCart()
    this.cartCount$.next(this.cartItemsList.length);
  }

  private getProductDetailCartModel(
    productDetail: any,
    product: any,
    price: number,
    quantity: number,
    size: any
  ): ProductDetailCartModel {
    const productDetailModel: ProductDetailCartModel = {
      id: productDetail.id,
      name: product.name,
      brand: product.brand,
      isAvailable: productDetail.isAvailable,
      quantity,
      size: size as SizeModel,
      price,
      salePrice: product.salePrice,
      images:productDetail?.images
    };
    return productDetailModel;
  }
}
