import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';
import * as ordersActions from '../actions/orders.actions';
import { HttpOrdersService } from '../http-services/http-orders.service';
import { AlertService } from '../services/alert.service';
import { AlertTypes } from '../enums/alertTypes';
import { CartService } from '../services/cart.service';

@Injectable()
export class OrdersEffects {
  public constructor(
    private actions$: Actions,
    private service: HttpOrdersService,
    private alertService: AlertService,
    private cartService: CartService
  ) {}

  public loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.loadOrders),
      switchMap(() =>
        this.service.getOrders().pipe(
          map((orders) => ordersActions.loadOrdersSuccess({ orders })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public createOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.CreateOrder),
      switchMap((action) =>
        this.service.createOrder(action.order).pipe(
          map(() => {
            this.alertService.showAlert('تمت اضافة طلبك بنجاح، الرجاء انتظار التأكيد الهاتفي', AlertTypes.success, 2000)
            this.cartService.clearProducts();
            return ordersActions.CreateOrderSuccess();
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );

  public UpdateOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ordersActions.UpdateOrderStatus),
      switchMap((action) =>
        this.service.updateOrderStatus(action.orderStatus).pipe(
          map((order) => {
            console.log(order)
            this.alertService.showAlert('تم تغيير حالة الطلب', AlertTypes.info, 2000)
            return ordersActions.UpdateOrderStatusSuccess({order});
          }),
          catchError((error) => {
            this.alertService.showAlert(error.error, AlertTypes.danger, 2000);
            return of();
          })
        )
      )
    )
  );
}
