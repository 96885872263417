import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent {
  @Input()
  adminName: string = ''

  constructor(private router: Router) { }

  public logout(){
    localStorage.removeItem('token')
    this.router.navigate(['myAdmin/login'])
  }
}
