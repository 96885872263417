<div class="container-fluid mt-3">
  <div class="row justify-content-center h5">الطلبات</div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-body table-responsive">
          <table class="table align-middle">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الإسم</th>
                <th scope="col">رقم الهاتف</th>
                <th scope="col">العنوان</th>
                <th scope="col">ملاحظات</th>
                <th scope="col">الحالة</th>
                <th scope="col">السعر الكلي</th>
                <th scope="col">المنتجات</th>
                <th scope="col">تفاصيل الطلب</th>
                <th scope="col">تاربخ الطلب</th>
                <th scope="col border-end">تغيير الحالة</th>
                <th scope="col">
                  <div class="input-group custom-width mb-1">
                    <label class="input-group-text fs-6" for="filter"
                      ><i class="bi bi-search"></i
                    ></label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="name"
                      placeholder="الإسم"
                      (change)="onSearchName($event)"
                    />
                  </div>
                  <div class="input-group custom-width">
                    <label class="input-group-text" for="filter"
                      ><i class="bi bi-funnel"></i
                    ></label>
                    <select
                      class="form-select form-select-sm"
                      id="filter"
                      (change)="onOrderStatusFilter($event)"
                    >
                      <option selected value="0">الكل</option>
                      <option
                        *ngFor="let status of orderStatuses; let i = index"
                        value="{{ i + 1 }}"
                      >
                        {{ status }}
                      </option>
                    </select>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of filteredOrders; let i = index">
                <td>{{ i + 1 }}</td>
                <td class="truncate" title="{{ order.contactName }}">
                  {{ order.contactName }}
                </td>
                <td class="truncate" title="{{ order.phoneNo }}">
                  {{ order.phoneNo }}
                </td>
                <td class="truncate" title="{{ order.address }}">
                  {{ order.address }}
                </td>
                <td class="truncate" title="{{ order.description }}">
                  {{ order.description }}
                </td>
                <td>{{ orderStatuses[order.status - 1] }}</td>
                <td>{{ order.totalPrice }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#productDetailsModal"
                    (click)="setSelectedDetailModel(order.productsDetails)"
                  >
                    عرض المنتجات
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#detailsModal"
                    (click)="setOrderDetails(order.details)"
                  >
                    عرض التفاصيل
                  </button>
                </td>
                <td>{{ order.date | date : "yyyy-MM-dd" }}</td>
                <td class="border-end">
                  <div class="input-group status-select-width">
                    <select
                      class="form-select form-select-sm"
                      (change)="onOrderStatusChange($event)"
                    >
                      <option
                        *ngFor="let status of orderStatuses; let i = index"
                        [value]="i + 1"
                        [disabled]="status === orderStatuses[order.status - 1]"
                      >
                        {{ status }}
                      </option>
                    </select>
                    <button
                      class="btn btn-sm btn-outline-light"
                      type="submit"
                      (click)="changeSubmit(order.id)"
                    >
                      حفظ
                    </button>
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="progress" *ngIf="isLoading$ | async">
            <div
              class="progress-bar bg-secondary progress-bar-striped progress-bar-animated w-100"
              role="progressbar"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="productDetailsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="productDetailsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="productDetailsModalLabel">المنتجات المطلوبة</h6>
        <div class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"
            ><span class="path1"></span><span class="path2"></span
          ></i>
        </div>
      </div>
      <div class="modal-body overlay overlay-block">
        <div class="table-responsive">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الموديل</th>
                <th scope="col">الاسم</th>
                <th scope="col">الكمية المطلوبة</th>
                <th scope="col">الحجم المطلوب</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pd of selectedDetails; let i = index">
                <th scope="row">{{ pd.productId }}</th>
                <td>
                  <img
                    class="me-2"
                    src="{{ pd.productDetails.images[0] }}"
                    alt="..."
                    style="width: 50px; height: 50px"
                  />
                </td>
                <td>{{ pd.productName }}</td>
                <td>{{ pd.quantity }}</td>
                <td>{{ getSizeName(pd.sizeId) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="detailsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="detailsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="detailsModalLabel">تفاصيل الطلب</h6>
        <div class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"
            ><span class="path1"></span><span class="path2"></span
          ></i>
        </div>
      </div>
      <div class="modal-body overlay overlay-block text-end">
        <pre>{{ orderDetails }}</pre>
      </div>
    </div>
  </div>
</div>
