import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ProductModel } from '../models/productModel';

export const loadProducts = createAction(
  'Load Products',
  props<{ subCategory: number; category: number; collection: string | null; isOnSale: boolean }>()
);
export const loadProductsSuccess = createAction(
  'Load Products Success',
  props<{ products: any[] }>()
);
export const loadProductsFailure = createAction(
  'Load Products Failure',
  props<{ error: HttpErrorResponse }>()
);
export const loadAllProducts = createAction(
  'Load All Products'
);
export const loadAllProductsSuccess = createAction(
  'Load All Products Success',
  props<{ products: any[] }>()
);
export const loadAllProductsFailure = createAction(
  'Load All Products Failure',
  props<{ error: HttpErrorResponse }>()
);
export const LoadSelectedProduct = createAction(
  'Load Selected Product',
  props<{ id: number }>()
);
export const LoadSelectedProductSuccess = createAction(
  'Load Selected Product Success',
  props<{ product: any }>()
);
export const LoadSelectedProductFailure = createAction(
  'Load Selected Product Failure',
  props<{ error: HttpErrorResponse }>()
);

export const createProduct = createAction(
  'Create Product',
  props<{ product: ProductModel }>()
);
export const CreateProductSuccess = createAction('Create Product Success');
export const CreateProductFailure = createAction(
  'Create Product Failure',
  props<{ error: any }>()
);

export const DeleteProduct = createAction(
  'Delete Product',
  props<{ id: number }>()
);

export const DeleteProductSuccess = createAction(
  'Delete Product Success',
  props<{ id: number }>()
);

export const DeleteProductFailure = createAction(
  'Delete Product Failure',
  props<{ error: any }>()
);

export const updateProduct = createAction(
  'Update Product',
  props<{ product: ProductModel }>()
);
export const UpdateProductSuccess = createAction('Update Product Success');
export const UpdateProductFailure = createAction(
  'Update Product Failure',
  props<{ error: any }>()
);
